import { Address } from '@/api/merchant-service/common-types';
import { myAxios } from '@/plugins/api';

export interface PointOfContact {
  name: string;
  phoneNumber: string;
  email: string;
  role: string;
}

export interface NewDeviceRequestSite {
  name: string;
  deliveryNotes?: string;
  address: Address;
}

export interface NewDeviceRequest {
  siteId: string;
  basket: {
    s1e2?: number;
    s1f2?: number;
    s1e2Dock?: number;
    s1f2Dock?: number;
  };
  pointOfContact: PointOfContact;
  site: NewDeviceRequestSite;
  deliveryAddressEdited: boolean;
  termsAccepted: boolean;
}

export interface Device {
  deviceId?: string;
  lastActivityAt?: string;
  firmwareVersion?: string;
  model?: string;
}

export interface DeviceConfig {
  id?: string;
  signature: {
    deviceSlogan?: string;
  };
}

export interface AssignDevice {
  otp: string;
}
class DeviceApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async newDeviceRequest(request: NewDeviceRequest) {
    return myAxios.post(`/merchants/${this.merchantId()}/devices`, request);
  }

  public async listDevices(siteId: string, pageNumber = 1, search?: string) {
    return myAxios
      .get<{ data: { items?: Device[]; totalPages: number } }>(`/merchants/${this.merchantId()}/devices`, { params: { siteId, pageNumber, search } })
      .then((response) => response.data.data);
  }

  public async fetchDeviceConfig(siteId: string, deviceId: string) {
    return myAxios.get<{ data: DeviceConfig }>(`/v1/merchants/${this.merchantId()}/sites/${siteId}/devices/${deviceId}`).then((response) => response.data.data);
  }

  public async updateDeviceConfig(siteId: string, deviceId: string, config: DeviceConfig) {
    return myAxios.patch(`/v1/merchants/${this.merchantId()}/sites/${siteId}/devices/${deviceId}`, config);
  }

  public async assignDevice(siteId: string, req: AssignDevice) {
    return myAxios
      .post<{ data: { id: string } }>(`/v1/merchants/${this.merchantId()}/sites/${siteId}/assign-device`, req, {
        validateStatus: (status) => status < 400,
      })
      .then((response) => response.data.data);
  }
}

export default DeviceApi;
