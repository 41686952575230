<template>
  <v-alert title="Billing communication settings" icon="mdi-information-box">
    <p class="mt-3">Send all billing communications (including invoices) to:</p>
    <v-form class="mt-3 d-flex" @submit.prevent>
      <v-text-field v-model.trim="email" type="email" max-width="250" density="compact" label="Email" :error-messages="validationErrors(v$.email.$errors)" />
      <transition>
        <v-btn-nd
          v-if="emailsChanged || saved"
          :disabled="v$.email.$invalid || saved"
          :loading="loading"
          size="default"
          :class="(saved ? 'bg-success' : 'bg-secondary') + ' on-secondary ms-3'"
          @click="update"
        >
          <v-icon icon="mdi-check-bold" />
        </v-btn-nd>
      </transition>
    </v-form>
  </v-alert>
</template>

<script setup lang="ts">
import { ErrorObject, useVuelidate } from '@vuelidate/core';
import { email as emailValidator, required } from '@vuelidate/validators';
import { computed, ref } from 'vue';
import { useApplicationStore } from '@/store/application';
import { useMerchantsStore } from '@/store/merchants';

const merchantStore = useMerchantsStore();
const applicationStore = useApplicationStore();

const email = ref(merchantStore.currentMerchant!.financeEmail);
const loading = ref(false);
const saved = ref(false);
const v$ = useVuelidate({ email: { $autoDirty: true, required, emailValidator } }, { email });

const emailsChanged = computed(() => email.value !== merchantStore.currentMerchant!.financeEmail);
const update = async () => {
  loading.value = true;
  try {
    await merchantStore.update({ financeEmail: email.value });
    saved.value = true;
    setTimeout(() => {
      saved.value = false;
    }, 3000);
  } catch (e) {
    console.error(e);
    applicationStore.notifyUser({ type: 'error', message: 'Failed to update email' });
  } finally {
    loading.value = false;
  }
};

const validationErrors = (errors: ErrorObject[]) => errors.map((e) => e.$message.toString());
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
