<template>
  <v-dialog v-model="dialogState" max-width="500px">
    <v-card>
      <template #title
        ><span class="text-primary">{{ title }}</span></template
      >
      <template #text>
        <div v-if="loading">
          <div class="d-flex justify-center"><v-progress-circular :model-value="progress" color="primary" /></div>
          <br />
          The report is being generated. Please wait...
        </div>
        <div v-else>Your report is generated and downloaded.</div>
        <sub>You can find all your generated reports in <a href="/reports">the reports section</a>.</sub>
      </template>
      <template #actions>
        <v-btn-nd class="ms-4 my-4" @click="dialogState = !dialogState">Close</v-btn-nd>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { AxiosError } from 'axios';
import { onMounted, computed, inject, ref, watch } from 'vue';
import ReportApi from '@/api/merchant-service/report';
import { API } from '@/plugins/api';
import { useApplicationStore } from '@/store/application';

const dialogState = defineModel<boolean>({ required: true });
const props = defineProps({ reportId: { type: String, default: undefined } });
const loading = ref(true);
const title = computed(() => (loading.value ? 'Downloading...' : 'Download complete'));
const progress = ref(10);

const api = inject<API>('api');
const appStore = useApplicationStore();

onMounted(() => {
  if (dialogState.value && props.reportId) {
    progress.value = 10;
    downloadReport(props.reportId);
  }
});
watch(
  () => props.reportId,
  () => {
    if (dialogState.value && props.reportId) {
      progress.value = 10;
      downloadReport(props.reportId);
    }
  },
);

const downloadReport = async (reportId: string) => {
  loading.value = true;
  try {
    const result = await api!.report.presignedUrl(reportId);
    progress.value = 100;
    setTimeout(() => {
      loading.value = false;
      ReportApi.downloadReport(result.presignedUrl, result.fileName);
    }, 500);
  } catch (e) {
    if (e instanceof AxiosError) {
      const axiosError = e as AxiosError;
      if (axiosError.response?.status === 404) {
        loading.value = false;
        dialogState.value = false;
        appStore.notifyUser({ message: 'The report you tried to download could not be found.', type: 'error' });
        return;
      }
      const data = axiosError.response?.data as unknown as { state: string } | undefined;
      if (data?.state !== 'READY') {
        progress.value += progress.value < 90 ? Math.random() * 4 + 1 : 0;
        setTimeout(() => downloadReport(reportId), Math.random() * 200 + 400);
      }
    }
  }
};
</script>
