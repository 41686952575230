import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';
import { App } from 'vue';
import BillingApi from '@/api/merchant-service/billing';
import DeviceApi from '@/api/merchant-service/device';
import MerchantApi from '@/api/merchant-service/merchant';
import PayoutApi from '@/api/merchant-service/payout';
import ReportApi from '@/api/merchant-service/report';
import SiteApi from '@/api/merchant-service/site';
import TransactionApi from '@/api/merchant-service/transaction';
import UserApi from '@/api/merchant-service/users';
import { useMerchantsStore } from '@/store/merchants';

export const myAxios = axios.create();

export default {
  install: (app: App) => {
    const merchantStore = useMerchantsStore();
    app.provide<API>('api', {
      merchant: new MerchantApi(),
      billing: new BillingApi(() => merchantStore.currentMerchant!.merchantId),
      user: new UserApi(() => merchantStore.currentMerchant!.merchantId),
      device: new DeviceApi(() => merchantStore.currentMerchant!.merchantId),
      site: new SiteApi(() => merchantStore.currentMerchant!.merchantId),
      transaction: new TransactionApi(() => merchantStore.currentMerchant!.merchantId),
      payout: new PayoutApi(() => merchantStore.currentMerchant!.merchantId),
      report: new ReportApi(() => merchantStore.currentMerchant!.merchantId),
    });
    myAxios.interceptors.request.use(async (config) => {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      config.headers.Authorization = `Bearer ${idToken}`;
      config.baseURL = import.meta.env.VITE_MERCHANT_SERVICE_BASE_URL;
      return config;
    });
  },
};

export type API = {
  merchant: MerchantApi;
  billing: BillingApi;
  user: UserApi;
  device: DeviceApi;
  site: SiteApi;
  transaction: TransactionApi;
  payout: PayoutApi;
  report: ReportApi;
};
