import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useApplicationStore, LOADING_STATES } from '@/store/application';
import { useAuthenticationStore } from '@/store/authentication';
import { Permission, useAuthorizationStore } from '@/store/authorization';
import { useMerchantsStore } from '@/store/merchants';

export const userMustBeAuthenticated = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authenticationStore = useAuthenticationStore();
  await authenticationStore.initialise();
  const isAuthenticated = authenticationStore.user.isAuthenticated;

  if (isAuthenticated) {
    next();
  } else {
    next(to.fullPath !== '/' ? '/signin?redirect=' + to.fullPath : '/signin');
  }
};

export const userMustBeStaff = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authorizationStore = useAuthorizationStore();

  if (authorizationStore.isStaff) {
    next();
  } else {
    next('/not-found');
  }
};

export const userMustNotBeAuthenticated = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authenticationStore = useAuthenticationStore();
  await authenticationStore.initialise();
  const isAuthenticated = authenticationStore.user.isAuthenticated;

  if (isAuthenticated) {
    next(to.query.redirect ? (to.query.redirect as string) : '/');
  } else {
    next();
  }
};

export const userMustBeConfigured = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authenticationStore = useAuthenticationStore();
  await authenticationStore.initialise();
  const isConfigured = authenticationStore.user.isConfigured;

  if (isConfigured) {
    next();
  } else {
    next('/onboarding/pending');
  }
};

export const merchantMustBeVerified = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  console.log('merchantMustBeVerified');
  const merchantStore = useMerchantsStore();
  console.log('Current merchant verified:', merchantStore.isCurrentMerchantVerified);

  if (merchantStore.isCurrentMerchantVerified) {
    next();
  } else {
    next('/onboarding/wizard');
  }
};

export const merchantMustNotBeVerified = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  console.log('merchantMustNotBeVerified');
  const merchantStore = useMerchantsStore();
  console.log('Current merchant verified:', merchantStore.isCurrentMerchantVerified);

  if (!merchantStore.isCurrentMerchantVerified) {
    next();
  } else {
    next('/');
  }
};

export const initialiseApplication = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const applicationStore = useApplicationStore();

  if (applicationStore.initialiseLoadState === LOADING_STATES.LOADED) {
    next();
  } else {
    if (to.path === '/onboarding' && to.query['deal']) {
      await applicationStore.initialize(); // initialize auth, user, and merchants
      useMerchantsStore().currentMerchant = undefined; // or the user will be redirected to the merchant's view
      next();
    } else {
      const { redirect } = (await applicationStore.initialize()) as { redirect: string };

      if (redirect) {
        next(redirect);
      } else {
        next();
      }
    }
  }
};

export const requiredCompanyPermissions =
  (permissions: Permission[]) => async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const authorizationStore = useAuthorizationStore();
    const hasPermission = permissions.every((permission) => authorizationStore.hasCompanyScoped(permission));

    if (authorizationStore.isStaff || hasPermission) {
      next();
    } else {
      next('/not-found');
    }
  };

export const requiredSitePermissions =
  (permissions: Permission[]) => async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const authorizationStore = useAuthorizationStore();
    const siteId = to.params.siteId as string;
    const hasPermission = permissions.every((permission) => authorizationStore.hasSiteScoped(siteId, permission));

    if (authorizationStore.isStaff || hasPermission) {
      next();
    } else {
      next('/not-found');
    }
  };

export const canManageCompanies = async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const authorizationStore = useAuthorizationStore();

  if (authorizationStore.canManageCompanies) {
    next();
  } else {
    next('/not-found');
  }
};

export const requiredAnySitePermissions =
  (permissions: Permission[]) => async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const authorizationStore = useAuthorizationStore();
    const hasPermission = permissions.every((permission) => authorizationStore.hasAnySiteScoped(permission));

    if (authorizationStore.isStaff || hasPermission) {
      next();
    } else {
      next('/not-found');
    }
  };
