import { BasecampApi } from '@tableyeti/merchant-service';
import { useAuthenticationStore } from '@/store/authentication';
import { useMerchantsStore } from '@/store/merchants';

export function useYetipayApi() {
  const auth = useAuthenticationStore();
  const merchants = useMerchantsStore();

  const api = new BasecampApi({
    basePath: import.meta.env.VITE_MERCHANT_SERVICE_BASE_URL,
    accessToken: auth.user.idToken,
    isJsonMime: () => true,
  });

  const currentMerchantId = merchants.currentMerchant!.merchantId;

  return { api, currentMerchantId };
}
