<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col v-if="!showForceNewPasswordForm" cols="12" md="6">
        <v-card v-if="!showConfirmationForm" class="px-4 py-8 mt-12" style="border-radius: 32px">
          <v-card-title>
            <h2 style="font-weight: 600">Sign in</h2>
          </v-card-title>

          <v-card-text class="mt-5">
            <div v-if="isGoogleLoginEnabled" align="center"><GoogleSSO flow="in" /></div>
            <br v-if="isGoogleLoginEnabled" />
            <v-divider v-if="isGoogleLoginEnabled" />
            <br v-if="isGoogleLoginEnabled" />
            <v-form @submit.prevent="signIn">
              <v-text-field
                v-model="email"
                placeholder="Email"
                required
                density="compact"
                autocapitalize="none"
                type="email"
                autocomplete="email"
                :error-messages="(v$.email.$dirty && v$.email.$invalid && v$.email.$silentErrors[0].$message.toString()) || ''"
                @input="authStore.resetErrorMessages"
              ></v-text-field>
              <v-text-field
                v-model="password"
                placeholder="Password"
                required
                density="compact"
                type="password"
                autocapitalize="none"
                autocomplete="password"
                :error-messages="(v$.password.$dirty && v$.password.$invalid && v$.password.$silentErrors[0].$message.toString()) || ''"
                @input="authStore.resetErrorMessages"
              ></v-text-field>
              <v-btn class="mt-5" block :disabled="isFormDisabled" :loading="signInLoading" type="submit" base-color="#AC7CF8">Sign in</v-btn>
              <v-alert v-if="authStore.signIn.errorMessage" density="compact" outlined type="warning" class="mt-4">
                {{ authStore.signIn.errorMessage }}
              </v-alert>
            </v-form>
          </v-card-text>
          <v-card-text>
            <p class="text-left font-weight-medium">No account? <router-link :to="signUpLink" style="color: #ac7cf8">Create account</router-link></p>
            <p class="text-left font-weight-medium">Forgot your password? <router-link to="/forgot" style="color: #ac7cf8">Reset password</router-link></p>
          </v-card-text>
        </v-card>

        <ConfirmUser v-else :email="email" @confirmation-success="userIsConfirmed" @exit="showConfirmationForm = false" />
      </v-col>

      <ForceNewPassword v-if="showForceNewPasswordForm" @success="forceSuccess" />
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, email as emailValidator, helpers } from '@vuelidate/validators';
import { computed, reactive, ref } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { useApplicationStore } from '@/store/application';
import { useAuthenticationStore, LOADING_STATES } from '@/store/authentication';

const authStore = useAuthenticationStore();
const applicationStore = useApplicationStore();
const router = useRouter();
const route = useRoute();

const signInLoading = ref(false);
const showConfirmationForm = ref(false);
const showForceNewPasswordForm = ref(false);
const email = ref('');
const password = ref('');

const v$ = useVuelidate(
  {
    email: {
      $autoDirty: true,
      required: helpers.withMessage('Required', required),
      email: helpers.withMessage('Invalid email address', emailValidator),
    },
    password: {
      $autoDirty: true,
      required: helpers.withMessage('Required', required),
    },
  },
  reactive({
    email,
    password,
  }),
);

const isFormDisabled = computed(() => v$.value.$invalid || authStore.signIn.status === LOADING_STATES.LOADING);
const isGoogleLoginEnabled = computed(() => route.query.g === null);
const signUpLink = computed(() => `/signup${route.query.redirect ? `?redirect=${route.query.redirect}` : ''}`);

const signIn = async () => {
  if (!(await v$.value.$validate())) {
    return;
  }
  signInLoading.value = true;
  const emailLowercase = email.value.toLocaleLowerCase();
  const signInSuccess = await authStore.initSignIn({ email: emailLowercase, password: password.value });
  if (signInSuccess) {
    router.push((route.query.redirect as RouteLocationRaw) || '/');
    return;
  }
  if (authStore.signIn.userRequiresConfirmation) {
    showConfirmationForm.value = true;
  }
  if (authStore.signIn.forceNewPassword) {
    showForceNewPasswordForm.value = true;
  }
  signInLoading.value = false;
};

const userIsConfirmed = () => {
  router.push((route.query.redirect as RouteLocationRaw) || '/');
  applicationStore.notifyUser({ message: 'Your email address is now verified.' });
};

const forceSuccess = () => {
  router.push((route.query.redirect as RouteLocationRaw) || '/');
  applicationStore.notifyUser({ message: 'Thanks! Your password has been updated.' });
};
</script>
