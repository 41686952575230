<template>
  <v-container>
    <v-skeleton-loader v-if="mode === 'update' && isDataLoading" type="list-item-two-line@5" />
    <v-form v-else>
      <p>What's the name of your site?</p>
      <v-text-field
        v-model.trim="modelValue.name"
        label="Site Name"
        :error-messages="validationErrors(v$.name.$errors)"
        :disabled="mode === 'update'"
        required
      />

      <v-text-field
        v-if="mode === 'update'"
        v-model.trim="modelValue.displayName"
        label="Site Display Name"
        :error-messages="validationErrors(v$.displayName.$errors)"
      />

      <p class="mt-10">What's the phone number for your site?</p>
      <PhoneNumber v-model="modelValue.phoneNumber" :error-messages="validationErrors(v$.phoneNumber.$errors)" />

      <p class="mt-10">What's the address of your site?</p>
      <v-text-field
        v-model.trim="modelValue.address.line1"
        label="Address line 1 (required)"
        :error-messages="validationErrors(v$.address.line1.$errors)"
        required
      />

      <v-text-field v-model.trim="modelValue.address.line2" class="mt-5" label="Address line 2" />

      <v-text-field
        v-model.trim="modelValue.address.city"
        class="mt-5"
        label="Address city (required)"
        :error-messages="validationErrors(v$.address.city.$errors)"
        required
      />

      <v-text-field
        v-model.trim="modelValue.address.postalCode"
        class="mt-5"
        label="Address postcode (required)"
        :error-messages="validationErrors(v$.address.postalCode.$errors)"
        required
      />

      <v-autocomplete
        v-model.trim="modelValue.address.country"
        class="mt-5"
        :items="tradedCountriesDisplay"
        label="Address country (required)"
        :error-messages="validationErrors(v$.address.country.$errors)"
        item-title="name"
        item-value="code"
        :disabled="mode === 'update'"
        required
      />

      <div class="pt-5">
        <div color="primary" class="text-uppercase font-weight-bold">short code</div>
        <div class="mb-3">Add an optional short code of 2 to 4 characters. This will show in your bank account when funds are paid for this site.</div>
        <v-text-field
          v-model.trim="modelValue.shortCode"
          class="mt-5"
          label="Short code (optional)"
          :error-messages="validationErrors(v$.shortCode.$errors)"
          @input="modelValue.shortCode = modelValue.shortCode?.toUpperCase()"
        />
      </div>

      <div class="pt-5">
        <div color="primary" class="text-uppercase font-weight-bold">pay-by-link</div>
        <div class="mb-3">To enable pay-by-link remote payments, contact your account manager, or <a href="mailto:help@yetipay.me">help@yetipay.me</a>.</div>
        <div>You'll also need to add a link to your terms & conditions which we will display to your customers when they pay by link.</div>
        <v-text-field
          v-model.trim="modelValue.payByLinkTermsUrl"
          class="mt-5"
          label="Terms & Conditions URL (optional)"
          :error-messages="validationErrors(v$.payByLinkTermsUrl.$errors)"
          hint="Without your T&Cs, Pay by link will not be available"
          persistent-hint
        />
      </div>
    </v-form>

    <v-alert v-if="primaryError" border="top" color="red lighten-2" dark>
      {{ primaryError }}
    </v-alert>

    <br />

    <div class="d-flex">
      <v-btn-nd v-if="showSecondary" @click="emit('secondary')">Prev</v-btn-nd>
      <v-btn class="ms-auto" :loading="isPrimaryLoading" :disabled="v$.$invalid" @click="emit('primary')">
        {{ primaryTextOverride ?? (mode == 'update' ? 'Save' : 'Submit') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { ErrorObject, useVuelidate } from '@vuelidate/core';
import { helpers, url, minLength, maxLength } from '@vuelidate/validators';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { postcodeValidator } from 'postcode-validator';
import { reactive } from 'vue';
import { Site } from '@/api/merchant-service/site';
import { tradedCountries } from '@/utils/config';
import { siteNameValidator, requiredField, siteDisplayNameValidator } from '@/utils/custom-validators';

const model = defineModel<Site>({
  required: true,
});

const { merchantCountry } = defineProps<{
  mode: 'create' | 'update';
  isDataLoading?: boolean;
  primaryTextOverride?: string;
  showSecondary?: boolean;
  merchantCountry?: string;
  isPrimaryLoading: boolean;
  primaryError?: string;
}>();

const emit = defineEmits<{
  primary: [];
  secondary: [];
}>();

const v$ = useVuelidate<Site>(
  {
    name: {
      $autoDirty: true,
      requiredField,
      siteName: siteNameValidator,
      minLength: minLength(5),
      maxLength: maxLength(22),
    },
    displayName: {
      $autoDirty: true,
      siteDisplayName: siteDisplayNameValidator,
      minLength: minLength(5),
      maxLength: maxLength(50),
    },
    phoneNumber: {
      requiredField,
      valid: (v: string) => parsePhoneNumber(v).valid,
    },
    address: {
      line1: { $autoDirty: true, requiredField },
      line2: {},
      city: { $autoDirty: true, requiredField },
      postalCode: { $autoDirty: true, requiredField, postalCodeValidator: (v: string) => postcodeValidator(v, merchantCountry ?? 'GB') },
      country: { $autoDirty: true, requiredField },
    },
    shortCode: {
      $autoDirty: true,
      validation: helpers.withMessage('Please enter a shortcode consisting of 2 to 4 alphanumeric characters', helpers.regex(/^[A-Z0-9]{2,4}$/)),
    },
    payByLinkTermsUrl: {
      $autoDirty: true,
      payByLink: !helpers.req || url,
    },
  },
  model,
);

const tradedCountriesDisplay = reactive(tradedCountries.filter((country) => !merchantCountry || country.code === merchantCountry));

const validationErrors = (errors: ErrorObject[]) => errors.map((e) => e.$message.toString());
</script>
