<template>
  <div class="d-flex flex-wrap">
    <v-select
      v-model.trim="v$.countryCode.$model"
      :items="phoneCountryCodes"
      item-title="text"
      hint="Country code"
      persistent-hint
      :error-messages="validationErrors(v$.countryCode.$errors)"
      required
      class="flex-grow-0 me-5"
    />
    <v-text-field
      v-model.trim="v$.phoneNumber.$model"
      type="number"
      :prefix="v$.countryCode.$model"
      hint="Phone number"
      persistent-hint
      :error-messages="validationErrors(v$.phoneNumber.$errors)"
      required
      class="no-inline-number-controls flex-grow-1"
    />
  </div>
</template>

<script setup lang="ts">
import { ErrorObject, useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { ref, watch, onUpdated } from 'vue';
import { phoneCountryCodes } from '@/utils/config';
import { requiredField } from '@/utils/custom-validators';

const model = defineModel<string | undefined>();

const form = ref({
  countryCode: '+44',
  phoneNumber: '',
});
const v$ = useVuelidate(
  {
    countryCode: { requiredField },
    phoneNumber: { phoneNumberValidator: helpers.withMessage('Please enter a valid phone number', () => parsePhoneNumber(model.value ?? '').valid) },
  },
  form,
);
onUpdated(() => {
  if (model.value) {
    const phoneNumber = parsePhoneNumber(model.value);
    if (phoneNumber.valid) {
      form.value.countryCode = `+${phoneNumber.countryCode}`;
      form.value.phoneNumber = phoneNumber.number.significant;
    } else {
      console.error('Invalid phone number', model.value);
    }
  }
});
watch(
  form,
  () => {
    if (!form.value.phoneNumber || form.value.phoneNumber === '') {
      model.value = undefined;
    } else {
      console.log(`${form.value.countryCode}${form.value.phoneNumber}`);
      model.value = `${form.value.countryCode}${form.value.phoneNumber}`;
    }
  },
  { deep: true },
);

const validationErrors = (errors: ErrorObject[]) => errors.map((e) => e.$message.toString());
</script>
