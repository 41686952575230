import posthog, { PostHog, CapturedNetworkRequest } from 'posthog-js';
import * as CookieConsent from 'vanilla-cookieconsent';
import { App } from 'vue';

export default {
  install(app: App) {
    const instance: PostHog = posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
      api_host: 'https://eu.posthog.com',
      capture_pageview: false,
      session_recording: {
        maskCapturedNetworkRequestFn: (request: CapturedNetworkRequest) => {
          if (request.name.includes('cognito-idp')) {
            return null;
          }
          return request;
        },
      },
    })!;
    if (!CookieConsent.acceptedCategory('analytics')) {
      instance.opt_out_capturing();
    } else {
      instance.opt_in_capturing();
    }

    // Global property for legacy option API
    app.config.globalProperties.$posthog = instance;
    // Provide for composition API using setup scripts
    app.provide<PostHog>('posthog', instance);
  },
};
