<template>
  <v-dialog v-model="dialogState" max-width="500px">
    <v-card>
      <template #title><span class="text-primary">Request refund</span></template>
      <v-card-text class="pb-0">
        <p v-if="props.transaction!.status !== 'partially-refunded'" class="mb-4">
          You can refund this transaction up to a maximum of the full transaction amount, {{ displayAmount(props.transaction!.amount!) }}. For a partial refund,
          edit the refund amount below.
        </p>
        <p v-else class="mb-4">
          {{ displayAmount(leftToRefundAmount) }} has already been refunded from this transaction. You can refund further up to a maximum of the remaining
          amount, {{ displayAmount(maxRefundAmount) }}.
        </p>
        <p class="mb-6">
          PSP reference: <kbd>{{ props.transaction!.pspReference }}</kbd
          ><br />
          Date of transaction: {{ props.transaction!.createdDate }}<br />
          Full amount: {{ displayAmount(props.transaction!.amount!) }}
        </p>
        <v-text-field
          v-model.number="amountToRefund"
          type="number"
          :min="0"
          :max="Math.ceil(maxRefund)"
          :prefix="getCurrencySymbol('en-UK', props.transaction?.amount?.currency ?? 'GBP')"
          label="Amount to refund"
          :error-messages="(v$.amountToRefund.$dirty && v$.amountToRefund.$invalid && v$.amountToRefund.$silentErrors[0].$message.toString()) || ''"
        />
      </v-card-text>
      <template #actions>
        <div class="d-flex flex-column w-100 px-4 pb-3">
          <v-text-field
            v-model="emailToSendReceipt"
            class="w-100"
            label="Send email receipt to (optional)"
            type="email"
            autocapitalize="none"
            autocomplete="email"
            clearable
            :error-messages="
              (v$.emailToSendReceipt.$dirty && v$.emailToSendReceipt.$invalid && v$.emailToSendReceipt.$silentErrors[0].$message.toString()) || ''
            "
          />
          <div class="d-flex w-100 mt-4">
            <v-btn-nd class="" @click="dialogState = !dialogState">Cancel</v-btn-nd>
            <v-spacer />
            <v-btn
              class="bg-primary on-primary"
              :disabled="v$.emailToSendReceipt.$invalid || v$.amountToRefund.$invalid"
              :loading="transactionsStore.refundLoading"
              @click="refund()"
              >Submit</v-btn
            >
          </div>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers, email as emailValidator } from '@vuelidate/validators';
import BigNumber from 'bignumber.js';
import { ref, computed, watch, reactive, onUpdated } from 'vue';
import { Amount } from '@/api/merchant-service/common-types';
import { TransactionV1 } from '@/api/merchant-service/transaction';
import { useApplicationStore } from '@/store/application';
import { useTransactionsStore } from '@/store/transactions';
import { displayAmount } from '@/utils/format';

const dialogState = defineModel<boolean>({ required: true });
const props = defineProps<{ transaction?: TransactionV1 }>();

const transactionsStore = useTransactionsStore();
const applicationStore = useApplicationStore();

const maxRefund = computed(() => {
  const sumRefunds = props.transaction?.refunds?.reduce((acc, refund) => acc + refund.amount.value, 0) ?? 0;
  const amountLeft = new BigNumber(props.transaction?.amount?.value ?? 0).minus(sumRefunds).toNumber();
  return new BigNumber(amountLeft).dividedBy(100).toNumber();
});
const maxRefundAmount = computed<Amount>(
  () =>
    ({
      value: maxRefund.value * 100,
      currency: props.transaction?.amount?.currency,
    }) as Amount,
);
const leftToRefundAmount = computed<Amount>(
  () =>
    ({
      value: props.transaction!.amount!.value - maxRefund.value * 100,
      currency: props.transaction?.amount?.currency,
    }) as Amount,
);
const amountToRefund = ref(0);
onUpdated(() => (amountToRefund.value = maxRefund.value));
watch(amountToRefund, (value) => {
  if (value > maxRefund.value) amountToRefund.value = maxRefund.value;
});

const emailToSendReceipt = ref(undefined);
const v$ = useVuelidate(
  {
    emailToSendReceipt: {
      $autoDirty: true,
      email: helpers.withMessage('Invalid email address', emailValidator),
    },
    amountToRefund: {
      $autoDirty: true,
      required: helpers.withMessage(
        'Required',
        helpers.withMessage('Amount must be greater than 0', (value: number) => value > 0),
      ),
      maxValue: helpers.withMessage('Amount must be less than or equal to the maximum refund amount', (value: number) => value <= maxRefund.value),
    },
  },
  reactive({
    emailToSendReceipt,
    amountToRefund,
  }),
);

const refund = async () => {
  if (!(await v$.value.$validate())) {
    return;
  }

  if (!props.transaction || !props.transaction.amount) return;
  const amount: Amount = {
    value: new BigNumber(amountToRefund.value).multipliedBy(100).toNumber(),
    currency: props.transaction.amount.currency,
  };

  try {
    await transactionsStore.refundTransaction(props.transaction.pspReference, amount, emailToSendReceipt.value);
    const message = transactionsStore.refundError
      ? `Unable to refund transaction. ${transactionsStore.refundError}`
      : 'It may take a few minutes for your refund to show in the transactions list.';
    applicationStore.notifyUser({ message, type: transactionsStore.refundError ? 'error' : 'success' });
    emailToSendReceipt.value = undefined;
    dialogState.value = false;
  } catch {
    applicationStore.notifyUser({ message: 'An error occurred while refunding the transaction.', type: 'error' });
  }
};

const getCurrencySymbol = (locale: string, currency: string) =>
  (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim();
</script>
