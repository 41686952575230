import { AxiosError } from 'axios';
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import { defineStore } from 'pinia';
import { Amount } from '@/api/merchant-service/common-types';
import PaymentLinkApi from '@/api/merchant-service/payment-links';
import TransactionApi, { TransactionStatusV1, TransactionType, TransactionV1, TrxRefund } from '@/api/merchant-service/transaction';
import { useMerchantsStore } from '@/store/merchants';

const api = new TransactionApi(() => useMerchantsStore().currentMerchant!.merchantId);
const paymentLinkApi = new PaymentLinkApi(() => useMerchantsStore().currentMerchant!.merchantId);

type FormattedTransactions = TransactionV1 & { type?: TransactionType };

export type TransactionsParams = {
  dates?: Date[];
  siteId?: string;
  type?: TransactionType;
  pspReference?: string;
};

type State = {
  transactionsParams: TransactionsParams;
  transactions: TransactionV1[] | undefined;
  paymentLinks: { [key: string]: string };
  transactionsLoading: boolean;
  transactionsError: string | undefined;
  transactionsNotAllowed: boolean;
  transactionsTotals: { totalsCount: number; totalsAmount: Amount; tipsAmount: Amount } | undefined;
  page: number;
  refundLoading: boolean;
  refundError: string | undefined;
  // Internal states, not meant to be used outside of the store
  _pagesLastKey: (string | undefined)[];
  _fetchedTotalPages: number | undefined;
};

const state = (): State => ({
  transactionsParams: {},
  transactions: undefined,
  paymentLinks: {},
  transactionsLoading: false,
  transactionsError: undefined,
  transactionsNotAllowed: false,
  transactionsTotals: undefined,
  page: 1,
  refundLoading: false,
  refundError: undefined,
  _pagesLastKey: [undefined],
  _fetchedTotalPages: undefined,
});

const finalPageKnown = ($this: State) => {
  return !!$this._fetchedTotalPages || !$this._pagesLastKey[$this._pagesLastKey.length - 1];
};
const finalPage = ($this: State) => {
  if ($this._fetchedTotalPages) return $this._fetchedTotalPages;
  if (!$this._pagesLastKey[$this._pagesLastKey.length - 1]) return $this._pagesLastKey.length - 1;
  return undefined;
};

const getters = {
  totalPages(this: State) {
    return this._fetchedTotalPages ?? this._pagesLastKey.length - (finalPageKnown(this) ? 1 : 0);
  },
  finalPageKnown(this: State) {
    return finalPageKnown(this);
  },
  isFinalPage(this: State) {
    return finalPageKnown(this) && finalPage(this) === this.page;
  },
};

const formatTransaction = (transaction: TransactionV1): FormattedTransactions => {
  let enableRefund = ['pending', 'captured', 'partially-refunded'].includes(transaction.status ?? 'captured');
  if (transaction.success === 'false') {
    enableRefund = false;
  }

  let status = transaction.status;
  if (!status) {
    status = transaction.success === 'true' ? TransactionStatusV1.Captured : TransactionStatusV1.Failed;
  }

  const refunds = transaction.refunds?.map(
    (refund: TrxRefund) =>
      ({
        pspReference: refund.pspReference,
        bookingDate: format(parseISO(refund.bookingDate), 'yyy-MM-dd HH:mm:ss'),
        amount: refund.amount,
        reason: refund.reason ?? '-',
        success: refund.success,
      }) as TrxRefund,
  );

  return {
    ...transaction,
    type: transaction.paymentLinkId ? 'Pay-by-link' : 'In-person',
    refunds,
    // TODO: chargebacks
    status,
    createdDate: format(parseISO(transaction.createdDate), 'yyy-MM-dd HH:mm:ss'),
    reason: transaction.success === 'true' ? undefined : transaction.reason,
    successBool: transaction.success === 'true' ? true : false,
    enableRefund,
  };
};

export const prepareDatesForApi = (dates: Date[] | undefined) => {
  if (dates?.length === 1 || (dates?.length === 2 && !dates[1])) {
    dates = [startOfDay(dates[0]), endOfDay(dates[0])];
  } else if (dates?.length === 2) {
    // without this, the end date time selects the current time, instead of the end of the day
    dates = [startOfDay(dates[0]), endOfDay(dates[1])];
  }
  return dates
    ?.filter((d) => !!d)
    .map((date) => date.toISOString())
    .join(',');
};

const _loadPaymentLinks = async ($this: State) => {
  if (!$this.transactions || $this.transactions.length === 0) return;

  const paymentLinkIds = $this.transactions.filter((transaction) => transaction.paymentLinkId).map((transaction) => transaction.paymentLinkId!);

  if (paymentLinkIds.length === 0) return;

  const paymentLinks = await paymentLinkApi.getPaymentLinks(paymentLinkIds);

  $this.paymentLinks = paymentLinks.reduce((acc, link) => {
    const indexOfFirstDash = link.description?.indexOf('-');
    return {
      ...acc,
      // The description is in the format "Customer name - description"
      [link.linkId]: link?.description?.slice(indexOfFirstDash + 1)?.trim() || '',
    };
  }, {});
};

const _loadTransactions = async ($this: State, page = $this.page, finalPage = true) => {
  $this.transactionsLoading = true;
  try {
    const { items, lastKey } = await api.getTransactions(
      // VueDatePicker sets the second date as null when only 1 is selected
      prepareDatesForApi($this.transactionsParams.dates),
      $this.transactionsParams.siteId,
      $this.transactionsParams.pspReference || undefined,
      $this.transactionsParams.type,
      $this._pagesLastKey[page - 1],
    );
    if (finalPage) {
      $this.transactions = items.map(formatTransaction);
      // We want to load the payment links asynchronously to not block the main render of transactions
      _loadPaymentLinks($this);
    }
    $this._pagesLastKey[page] = lastKey;
    $this.transactionsNotAllowed = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error instanceof AxiosError && (error as AxiosError).response?.status === 403) {
      $this.transactionsNotAllowed = true;
    } else {
      $this.transactionsError = error.response?.data?.error || error?.message || 'Something went wrong';
    }
  } finally {
    if (finalPage) {
      $this.transactionsLoading = false;
    }
  }
};

const _fetchTotalPages = async ($this: State) => {
  try {
    const { totalPages } = await api.getTransactions(
      // VueDatePicker sets the second date as null when only 1 is selected
      prepareDatesForApi($this.transactionsParams.dates),
      $this.transactionsParams.siteId,
      $this.transactionsParams.pspReference,
      $this.transactionsParams.type,
      $this._pagesLastKey[$this.page - 1],
      true,
    );
    $this._fetchedTotalPages = totalPages;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
  }
};

const actions = {
  async refresh(this: State) {
    this.page = 1;
    this.transactions = [];
    this._pagesLastKey = [undefined];
    this._fetchedTotalPages = undefined;
    if (!this._fetchedTotalPages && this.transactionsParams.dates) {
      // We don't want to count pages for an infinite time range
      _fetchTotalPages(this); // can run without await as not crutial for the UI
    }
    await _loadTransactions(this);
  },
  async setPage(this: State, newPage: number) {
    this.page = newPage;
    let stepPage = Math.min(newPage, this._pagesLastKey.length - 1);
    do {
      await _loadTransactions(this, stepPage, stepPage === newPage);
    } while (stepPage++ != newPage);
  },
  async refundTransaction(this: State, pspReference: string, amount?: Amount, emailToSendReceipt?: string) {
    this.refundLoading = true;
    this.refundError = undefined;
    const transactionToRefund = this.transactions!.find((t) => t.pspReference === pspReference)!;
    transactionToRefund.enableRefund = false;
    try {
      await api.refundTransaction(pspReference, amount, emailToSendReceipt);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.refundError = error.response.data.error || 'Something went wrong';
      console.error(this.refundError);
      const leftInTransaction =
        transactionToRefund.amount.value - (transactionToRefund.refunds?.reduce((acc, r) => acc + r.amount.value, 0) ?? 0) - (amount?.value ?? 0);
      transactionToRefund.enableRefund = leftInTransaction > 0;
    } finally {
      this.refundLoading = false;
    }
  },
  async sendRefundTransactionEmail(this: State, pspReference: string, email: string, refundPspReference: string) {
    try {
      await api.sendRefundTransactionEmail(pspReference, email, refundPspReference);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.refundError = error.response.data.error || 'Something went wrong';
      console.error(this.refundError);
    }
  },
  async getTransactionTotals(this: State) {
    if (!this.transactionsParams.siteId) {
      this.transactionsTotals = undefined;
      return;
    }

    try {
      const result = await api.getTotals(this.transactionsParams.siteId!, startOfDay(new Date()));
      this.transactionsTotals = result;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
    }
  },
};

export const useTransactionsStore = defineStore('transactions', {
  state,
  getters,
  actions,
});
