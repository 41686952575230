<template>
  <h2>Integrations</h2>

  <!-- PUSH -->
  <v-card subtitle="Configuration for Till > Yeti integration" elevation="3" class="ma-3">
    <template #title>
      <div class="d-flex align-center justify-space-between">
        <div>
          <span class="font-weight-black">Yeti Connect Push</span>
          <v-chip
            v-if="mode === Mode.VIEW"
            class="ml-3"
            :color="isPushIntegrated ? 'success' : 'error'"
            :text="isPushIntegrated ? 'Connected' : 'Not connected'"
            :variant="!isPushIntegrated ? 'outlined' : undefined"
          />
        </div>
        <v-btn-nd v-if="mode === Mode.VIEW" variant="text" @click="mode = Mode.EDIT">
          <v-icon>mdi-pencil</v-icon>
        </v-btn-nd>
        <v-btn-nd v-else variant="text" @click="mode = Mode.VIEW">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn-nd>
      </div>
    </template>

    <v-card-text v-if="mode === Mode.EDIT">
      <div v-if="isPushIntegrated" class="d-flex align-center">
        <p class="me-3">
          Integrated with: <kbd>{{ push?.provider }}</kbd>
        </p>
        <v-btn
          :loading="loading"
          color="error"
          @click="
            emit('updatePush', undefined);
            mode = Mode.VIEW;
          "
          >Remove integration</v-btn
        >
      </div>

      <div v-else class="d-flex align-center">
        <p class="me-3">Integrate with:</p>
        <v-select v-model="pushProvider" hide-details max-width="300" class="me-3" :items="pushIntegrations" />
        <v-btn
          :loading="loading"
          @click="
            emit('updatePush', pushToEmit);
            mode = Mode.VIEW;
          "
          >Save</v-btn
        >
      </div>
    </v-card-text>

    <v-card-text v-else-if="isPushIntegrated">
      <p>
        Integrated with: <kbd>{{ push?.provider }}</kbd>
      </p>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { SiteInternalSettingsConnectPush, SiteInternalSettingsConnectPushProviderEnum } from '@tableyeti/merchant-service';
import { computed, ref } from 'vue';

const { push } = defineProps<{ push?: SiteInternalSettingsConnectPush; loading?: boolean }>();
const emit = defineEmits<{ updatePush: [SiteInternalSettingsConnectPush | undefined] }>();

const isPushIntegrated = computed(() => !!push);

enum Mode {
  VIEW = 'view',
  EDIT = 'edit',
}
const mode = ref<Mode>(Mode.VIEW);
const pushIntegrations = Object.values(SiteInternalSettingsConnectPushProviderEnum).map((value) => ({
  title: value,
  value,
  props: { disabled: value !== SiteInternalSettingsConnectPushProviderEnum.PsConnect },
}));

const pushProvider = ref<SiteInternalSettingsConnectPushProviderEnum | undefined>(undefined);

const pushToEmit = computed<SiteInternalSettingsConnectPush | undefined>(() => ({ provider: pushProvider.value! }));
</script>
