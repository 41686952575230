import { defineStore } from 'pinia';
import { MerchantWithPermissions } from '@/api/merchant-service/merchant';

export enum Role {
  CompanyOwner = 'CompanyOwner',
  SiteManager = 'SiteManager',
}

export enum Permission {
  Device_OrderDevice = 'Device:OrderDevice',
  Device_ListDevices = 'Device:ListDevices',
  Device_AssignDevice = 'Device:AssignDevice',
  Site_ListSites = 'Site:ListSites',
  Site_GetSite = 'Site:GetSite',
  Site_CreateSite = 'Site:CreateSite',
  Site_UpdateSite = 'Site:UpdateSite',
  Transaction_ListTransactions = 'Transaction:ListTransactions',
  Transaction_RefundTransaction = 'Transaction:RefundTransaction',
  Payout_ListPayouts = 'Payout:ListPayouts',
  Payout_GetPayout = 'Payout:GetPayout',
  Report_ListReports = 'Report:ListReports',
  Report_CreateReports = 'Report:CreateReports',
  Report_DownloadReport = 'Report:DownloadReport',
  Billing_ListPaymentMethods = 'Billing:ListPaymentMethods',
  Billing_AddPaymentMethod = 'Billing:AddPaymentMethod',
  Billing_ListInvoices = 'Billing:ListInvoices',
  Billing_SetDefaultPaymentMethod = 'Billing:SetDefaultPaymentMethod',
  Billing_DeleteDefaultPaymentMethod = 'Billing:DeleteDefaultPaymentMethod',
  User_ListUsers = 'User:ListUsers',
  User_InviteUser = 'User:InviteUser',
  User_UpdateUser = 'User:Update',
  User_RemoveUser = 'User:RemoveUser',
  User_ResetPassword = 'User:ResetPassword',
  Company_AccessKyc = 'Company:AccessKyc',
  Company_UpdateDetails = 'Company:UpdateDetails',
  Company_GetRates = 'Company:GetRates',
  Company_Deactivate = 'Company:Deactivate',
}

export interface Permissions {
  companyScoped: Permission[];
  siteScoped: { siteId: string; permissions: Permission }[];
}

type State = {
  isStaff: boolean;
  permissions: Permissions;
  _anyCompanyListUsers: boolean;
  _associatedMerchantsCount: number;
};

const state = (): State => ({
  isStaff: false,
  permissions: {
    companyScoped: [],
    siteScoped: [],
  },
  _anyCompanyListUsers: false,
  _associatedMerchantsCount: 0,
});

const getters = {
  canManageCompanies(this: State): boolean {
    return this.isStaff || this._anyCompanyListUsers || this._associatedMerchantsCount > 1;
  },
};

const actions = {
  setMerchant(this: State, merchant: MerchantWithPermissions) {
    this.permissions = merchant.permissions;
  },
  initWithAssociatedMerchants(this: State, merchants: MerchantWithPermissions[]) {
    this._associatedMerchantsCount = merchants.length;
    this._anyCompanyListUsers = merchants.some((merchant) => merchant.permissions.companyScoped.includes(Permission.User_ListUsers));
  },
  hasCompanyScoped(this: State, permission: Permission): boolean {
    return this.isStaff || this.permissions.companyScoped.includes(permission);
  },
  hasSiteScoped(this: State, siteId: string, permission: Permission): boolean {
    if (this.isStaff) {
      return true;
    }
    if (this.permissions.siteScoped.find((site) => site.siteId === siteId)?.permissions.includes(permission) ?? false) {
      return true;
    }
    return this.permissions.siteScoped.find((site) => site.siteId === '*')?.permissions.includes(permission) ?? false;
  },
  hasAnySiteScoped(this: State, permission: Permission): boolean {
    if (this.isStaff) {
      return true;
    }
    return this.permissions.siteScoped.some((site) => site.permissions.includes(permission));
  },
};

export const useAuthorizationStore = defineStore('authorization', {
  state,
  getters,
  actions,
});
