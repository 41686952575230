<template>
  <h2>Tips</h2>

  <v-card elevation="3" class="ma-3 pa-3">
    <v-row no-gutters>
      <v-col :cols="mdAndDown ? 12 : 4">
        <v-switch v-model="tipsEnabled" label="Enable tips for site" inset color="success" class="mx-2" />
      </v-col>

      <v-col :cols="mdAndDown ? (xs ? 12 : 6) : 4">
        <v-select
          v-if="tipsEnabled"
          v-model="_settings.provider"
          label="Select the tip provider"
          :items="tipsProviders"
          :error-messages="(v$.provider.$silentErrors.map((e: ErrorObject) => e.$message) as string[]) || []"
          class="mx-2"
          style="min-width: 200px"
        />
      </v-col>

      <v-col :cols="mdAndDown ? (xs ? 12 : 6) : 4">
        <v-text-field
          v-if="tipsEnabled && _settings.provider === 'tipjar'"
          v-model="_settings.tipjarShortcode"
          label="TipJar's shortcode for site"
          clearable
          :error-messages="(v$.tipjarShortcode.$silentErrors.map((e: ErrorObject) => e.$message) as string[]) || []"
          class="mx-2"
          style="min-width: 230px"
        />
      </v-col>

      <v-col cols="12">
        <v-checkbox
          v-if="tipsEnabled"
          v-model="_settings.skipTipsIfServiceChargeIncluded"
          label="Skip tip modal when service charge is included"
          inset
          color="primary"
          class="mx-2"
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn :loading="loading" :disabled="settingsUnchanged || v$.$invalid" @click="emit('updateInternalSettings', settingsToEmit)">save changes</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script setup lang="ts">
import { SiteInternalSettingsTips } from '@tableyeti/merchant-service';
import useVuelidate, { ErrorObject } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { cloneDeep, isEqual } from 'lodash-es';
import { computed, ref } from 'vue';
import { useDisplay } from 'vuetify';

const { settings } = defineProps<{ settings: SiteInternalSettingsTips; loading?: boolean }>();
const emit = defineEmits<{ updateInternalSettings: [SiteInternalSettingsTips | undefined] }>();

const { xs, mdAndDown } = useDisplay();

const tipsEnabled = ref(!!settings);
const _settings = ref<SiteInternalSettingsTips>(cloneDeep(settings ?? {}));
const v$ = useVuelidate<SiteInternalSettingsTips>(
  {
    provider: { required: requiredIf(() => tipsEnabled.value) },
    tipjarShortcode: { required: requiredIf(() => tipsEnabled.value && _settings.value.provider === 'tipjar') },
    skipTipsIfServiceChargeIncluded: {},
  },
  _settings.value,
);

const tipsProviders = [
  { title: 'TipJar', value: 'tipjar' },
  { title: 'Yetipay', value: 'yetipay' },
];

const settingsToEmit = computed<SiteInternalSettingsTips | undefined>(() =>
  tipsEnabled.value
    ? {
        provider: _settings.value.provider,
        tipjarShortcode: _settings.value.tipjarShortcode || undefined,
        skipTipsIfServiceChargeIncluded: _settings.value.skipTipsIfServiceChargeIncluded || undefined,
      }
    : undefined,
);
const settingsUnchanged = computed(() => isEqual(settings, settingsToEmit.value) && tipsEnabled.value === !!settings?.provider);
</script>
