<template>
  <v-card>
    <v-card-title>Billing rates</v-card-title>
    <v-card-text>
      <v-form v-if="!shouldDisableForDate">
        <h3 class="mx-2">Contract details</h3>
        <v-select
          v-model="pendingDealRates.transactionRates.billingType"
          :items="Object.values(BillingType)"
          item-value=""
          label="Type"
          class="mx-2 pt-2"
          min-width="250"
        />
        <h3 class="mx-2">Device rental</h3>
        <div class="d-flex">
          <NumberInput v-model="pendingDealRates.deviceRate" label="Monthly rate" class="ma-2" />
        </div>
        <h3 class="mx-2">Contract end date</h3>
        <VueDatePicker
          :model-value="pendingDealRates.contractEndDate"
          locale="en-UK"
          format="dd/MM/yyyy"
          placeholder="Contract end date"
          :min-date="new Date()"
          :enable-time-picker="false"
          class="px-2 pt-2 pb-6"
          @update:model-value="
            (modelData?: Date) => {
              // need to remove rather than setting to undefined so deep equality check works
              if (modelData) {
                pendingDealRates.contractEndDate = modelData.toISOString();
              } else {
                delete pendingDealRates.contractEndDate;
              }
            }
          "
        />
        <h3 class="mx-2">Domestic</h3>
        <div class="d-flex">
          <NumberInput v-model="pendingDealRates.transactionRates.domestic.auth" label="Auth" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.domestic.debit" label="Debit" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.domestic.credit" label="Credit" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.domestic.premium" label="Premium" class="ma-2" />
        </div>
        <h3 class="mx-2">Intra</h3>
        <div class="d-flex">
          <NumberInput v-model="pendingDealRates.transactionRates.intra.auth" label="Auth" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.intra.debit" label="Debit" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.intra.credit" label="Credit" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.intra.premium" label="Premium" class="ma-2" />
        </div>
        <h3 class="mx-2">Inter</h3>
        <div class="d-flex">
          <NumberInput v-model="pendingDealRates.transactionRates.inter.auth" label="Auth" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.inter.debit" label="Debit" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.inter.credit" label="Credit" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.inter.premium" label="Premium" class="ma-2" />
        </div>
        <h3 class="mx-2">Amex</h3>
        <div class="d-flex">
          <NumberInput v-model="pendingDealRates.transactionRates.amex.auth" label="Auth" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.amex.card" label="Card" class="ma-2" />
        </div>
        <h3 class="mx-2">Other</h3>
        <div class="d-flex">
          <NumberInput v-model="pendingDealRates.transactionRates.other.auth" label="Auth" class="ma-2" />
          <NumberInput v-model="pendingDealRates.transactionRates.other.card" label="Card" class="ma-2" />
        </div>
        <div class="d-flex justify-end">
          <v-checkbox v-if="hasPendingChanges" v-model="shouldBackfill" label="Backfill rates to start of month?" inset color="primary" class="mx-4" />
          <v-btn :disabled="!hasPendingChanges" :loading="isLoading" @click="update">save changes</v-btn>
        </div>
      </v-form>
      <v-alert v-else type="error">It is not possible to update billing rates today, please come back tomorrow...</v-alert>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { isLastDayOfMonth } from 'date-fns';
import _, { pick } from 'lodash';
import { computed, ref } from 'vue';
import NumberInput from '../NumberInput.vue';
import { BillingType, DealRates, UpdateRates } from '@/api/merchant-service/merchant';

const { dealRates } = defineProps<{ dealRates: DealRates; isLoading: boolean }>();
const emit = defineEmits<{
  update: [rates: UpdateRates];
}>();

const pendingDealRates = ref<DealRates>(_.cloneDeep(dealRates));
const shouldBackfill = ref(true);
const shouldDisableForDate = ref(isLastDayOfMonth(new Date()));
const hasPendingChanges = computed(() => !_.isEqual(dealRates, pendingDealRates.value));

const update = () => {
  const body = { ...pick(pendingDealRates.value, 'transactionRates', 'contractEndDate', 'deviceRate'), shouldBackfill: shouldBackfill.value };
  emit('update', body);
};
</script>
