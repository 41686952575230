<template>
  <h2 class="headline font-weight-medium my-5">Your payment methods</h2>

  <v-alert v-if="showMinimumMethodWarning" outlined type="info" class="mb-5">
    We recommend keeping at least two different payment method on file at all times. This ensures that your subscription will not be interrupted in the event
    that your primary payment method fails.
  </v-alert>

  <v-dialog :activator="selectedPaymentMethodItem" max-width="500">
    <template #activator="{ props: activatorProps }">
      <v-row v-if="showBillingMethods">
        <v-col v-for="method in billingMethods" :key="method.id" class="mb-2" cols="12" sm="6" md="4" xxl="2">
          <PaymentMethodCard
            :method="method"
            :is-default="method.id === billingStore.defaultPaymentMethodId"
            :activator-props="activatorProps"
            :show-actions="
              authorizationStore.hasCompanyScoped(Permission.Billing_SetDefaultPaymentMethod) &&
              authorizationStore.hasCompanyScoped(Permission.Billing_DeleteDefaultPaymentMethod)
            "
            @action="paymentMethodCallback"
          />
        </v-col>
      </v-row>
    </template>

    <template #default="{ isActive }">
      <v-card :prepend-icon="dialog.icon" :title="dialog.title">
        <template #default>
          <div class="mx-6">
            {{ dialog.message }}
            <PaymentMethodCard :method="billingMethods.find((m) => m.id === selectedPaymentMethodItem)!" class="mt-4 border" />
          </div>
        </template>
        <template #actions>
          <v-btn-nd text="Cancel" class="ms-4 my-4" @click="isActive.value = false" />
          <v-spacer></v-spacer>
          <v-btn :text="dialog.primaryActionText" class="me-4 my-4 bg-primary on-primary" :loading="actionLoading" @click="dialog.action(isActive)" />
        </template>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, Ref, inject } from 'vue';
import { PaymentMethod } from '@/api/merchant-service/billing';
import { API } from '@/plugins/api';
import { useApplicationStore } from '@/store/application';
import { useAuthorizationStore, Permission } from '@/store/authorization';
import { useBillingStore } from '@/store/billing';

const billingStore = useBillingStore();
const applicationStore = useApplicationStore();
const authorizationStore = useAuthorizationStore();
const api = inject<API>('api');

const billingMethods = computed<PaymentMethod[]>(() => billingStore.paymentMethods ?? []);

const selectedPaymentMethodItem = ref<string | undefined>(undefined);
const actionLoading = ref(false);
const setPaymentMethod = async (isActive: Ref<boolean>) => {
  actionLoading.value = true;
  try {
    await api!.billing.setDefaultPaymentMethod(selectedPaymentMethodItem.value!);
    await billingStore.initialize();
    isActive.value = false;
  } catch (error) {
    console.error(error);
    applicationStore.notifyUser({ message: 'Failed to set default payment method', type: 'error' });
  } finally {
    actionLoading.value = false;
  }
};
const deletePaymentMethod = async (isActive: Ref<boolean>) => {
  actionLoading.value = true;
  try {
    await api!.billing.deletePaymentMethod(selectedPaymentMethodItem.value!);
    await billingStore.initialize();
    isActive.value = false;
  } catch (error) {
    console.error(error);
    applicationStore.notifyUser({ message: 'Failed to delete default payment method', type: 'error' });
  } finally {
    actionLoading.value = false;
  }
};
const dialogState = ref<'default' | 'delete'>('default');
const dialog = computed(() => ({
  icon: dialogState.value === 'default' ? 'mdi-credit-card' : 'mdi-delete',
  title: dialogState.value === 'default' ? 'Change default payment method' : 'Delete payment method',
  message: dialogState.value === 'default' ? 'Do you want to make this your default payment method?' : 'Do you want to delete this payment method?',
  action: dialogState.value === 'default' ? setPaymentMethod : deletePaymentMethod,
  primaryActionText: dialogState.value === 'default' ? 'Confirm' : 'Delete',
}));
const paymentMethodCallback = ({ action, paymentMethodId }: { action: 'default' | 'delete'; paymentMethodId: string }) => {
  selectedPaymentMethodItem.value = paymentMethodId;
  dialogState.value = action;
};

const showBillingMethods = computed(() => billingMethods.value.length > 0);
const showMinimumMethodWarning = computed(() => billingMethods.value.length < 2);
</script>
