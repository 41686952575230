<template>
  <v-card class="pa-5 mt-12">
    <v-card-title>
      <h2 class="font-weight-medium">We emailed you</h2>
    </v-card-title>
    <v-card-text class="mt-5">Your code is on the way. To log in, enter the code we emailed to you. It may take a minute to arrive.</v-card-text>
    <v-card-text>
      <v-form @submit.prevent="confirmUser">
        <v-text-field
          v-model="confirmationCode"
          placeholder="Enter your code"
          type="number"
          class="no-inline-number-controls"
          required
          density="compact"
          autocapitalize="none"
          autocomplete="off"
          :error-messages="(v$.confirmationCode.$dirty && v$.confirmationCode.$invalid && v$.confirmationCode.$silentErrors[0].$message.toString()) || ''"
          @input="authStore.resetErrorMessages"
        ></v-text-field>
        <v-btn :loading="authStore.confirmUser.status === LOADING_STATES.LOADING" :disabled="isConfirmButtonDisabled()" class="mt-5" block type="submit">
          Confirm Account
        </v-btn>
        <v-alert v-if="authStore.confirmUser.errorMessage" density="compact" outlined type="warning" class="mt-4">
          {{ authStore.confirmUser.errorMessage }}
        </v-alert>
      </v-form>
    </v-card-text>
    <v-card-text>
      <v-btn-nd :disabled="isResendButtonDisabled()" block @click.prevent="resendConfirmationCode">Resend code</v-btn-nd>
      <p class="text-left mt-5"><a href="/" @click.prevent="exit">Back</a></p>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { ref } from 'vue';
import { useAuthenticationStore, LOADING_STATES } from '@/store/authentication';

const { email } = defineProps<{ email: string }>();
const emit = defineEmits<{
  confirmationSuccess: [];
  confirmationFailure: [];
  resendSuccess: [];
  exit: [];
}>();
const confirmationCode = ref('');

const authStore = useAuthenticationStore();

const v$ = useVuelidate(
  {
    confirmationCode: {
      $autoDirty: true,
      required: helpers.withMessage('Required', required),
    },
  },
  { confirmationCode },
);

const isConfirmButtonDisabled = () => v$.value.$invalid || authStore.confirmUser.status === LOADING_STATES.LOADING;

const isResendButtonDisabled = () => authStore.confirmUser.status === LOADING_STATES.LOADING;

const confirmUser = async () => {
  const emailLowercase = email.toLocaleLowerCase();
  const confirmUserSuccess = await authStore.initConfirmUser({ email: emailLowercase, confirmationCode: confirmationCode.value });
  if (confirmUserSuccess) {
    emit('confirmationSuccess');
    return;
  }
  emit('confirmationFailure');
};

const resendConfirmationCode = async () => {
  const emailLowercase = email.toLocaleLowerCase();
  const resendSuccess = await authStore.initResendConfirmationCode(emailLowercase);
  if (resendSuccess) {
    emit('resendSuccess');
    return;
  }
};

const exit = () => emit('exit');
</script>
