<template>
  <v-alert v-if="apiResults.payout.state.error" color="red lighten-2" dark class="py-3 px-6 ml-3">
    Issue when loading this page. Please refresh and if this issue persists then contact TableYeti support.
  </v-alert>
  <v-skeleton-loader v-else-if="apiResults.payout.state.loading" class="ma-2" type="chip" />
  <div v-else class="d-flex flex-row flex-wrap">
    <v-chip class="ma-2" color="green darken-4" text-color="white">Total paid out: {{ displayAmount(apiResults.payout.data!.amount) }}</v-chip>
    <v-chip class="ma-2" color="#EA4335" text-color="#EA4335" variant="outlined">Deducted: {{ displayAmount(deducted) }}</v-chip>
    <v-chip class="ma-2" color="deep-purple accent-4" variant="outlined">
      Payout Ref:&nbsp;<kbd>{{ apiResults.payout.data!.id }}</kbd>
    </v-chip>
    <v-chip class="ma-2" color="indigo darken-4" variant="outlined">Site: {{ apiResults.payout.data!.site.siteName }}</v-chip>
    <v-chip class="ma-2" color="blue-grey accent-4" variant="outlined">Payout Date: {{ displayTime(apiResults.payout.data!.date) }}</v-chip>
  </div>

  <v-alert v-if="apiResults.payout.data && apiResults.payout.data.payoutTotalError" color="red lighten-2" dark class="mt-5">
    We are aware that the total paid out does not match the line items displayed here, we're already on it!
  </v-alert>

  <div class="mt-5">
    <h3 class="mb-5">Daily summary</h3>
    <v-skeleton-loader v-if="apiResults.payout.state.loading" type="table" />
    <DailySummary v-else :days="dailySummary!" :default-currency="apiResults.payout.data!.amount.currency" />
  </div>

  <div class="mt-5">
    <h3 class="mb-5">All movements</h3>
    <v-skeleton-loader v-if="apiResults.payout.state.loading" type="table" />
    <v-alert v-else-if="apiResults.payout.state.error" color="red lighten-2" dark class="py-3 px-6 ml-3">
      Issue when loading the movements. Please refresh and if this issue persists then contact TableYeti support.
    </v-alert>
    <template v-else>
      <v-data-table
        :headers="transactionsTableHeaders"
        :items="apiResults.payout.data!.transfers"
        item-value="transferId"
        :sort-by="[{ key: 'bookingDate', order: 'desc' }]"
        disable-sort
        :search="search"
        :custom-filter="filter"
        show-expand
        expand-on-click
        class="elevation-1"
      >
        <template #top>
          <v-toolbar class="pt-5" :height="true || mdAndUp ? '90px' : '200px'">
            <v-container>
              <v-row no-gutters>
                <v-col cols="12" md="4">
                  <v-select v-model="selectedType" :items="typeFilters" label="Filter by type" clearable class="mr-2">
                    <template #item="{ props, item }">
                      <v-list-item v-bind="props" title="" class="text-uppercase">{{ item.value }}</v-list-item>
                    </template>
                    <template #selection="{ item }">
                      <span class="text-uppercase">{{ item.value }}</span>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="selectedPaymentMethod" :items="paymentMethodFilters" label="Filter by method" clearable class="mr-2">
                    <template #item="{ item, props }">
                      <v-list-item v-bind="props" title=""><PaymentMethod v-model="item.value" /></v-list-item>
                    </template>
                    <template #selection="{ item }">
                      <PaymentMethod v-model="item.value" />
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-chip class="mt-4" color="grey darken-4" variant="outlined">
                    Total with filter(s):
                    {{ displayAmount(filteredTotal) }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>
          </v-toolbar>
        </template>

        <template #item.amount="{ item }">
          {{ displayAmount(item.amount.value + (item.includedTip?.value ?? 0) + (item.additionalTip?.value ?? 0)) }}
        </template>
        <template #item.tip="{ item }">
          {{ displayAmount(item.includedTip ?? (item.additionalTip ? item.additionalTip : null) ?? 0) }}
        </template>
        <template #item.bookingDate="{ item }">
          {{ displayTime(item.bookingDate) }}
        </template>
        <template #item.type="{ item }">
          <span class="text-uppercase">{{ item.type }}</span>
        </template>
        <template #item.pspPaymentReference="{ item }">
          <span v-if="item.type === 'internal transfer'">
            ID: <kbd>{{ item.transferId }}</kbd>
          </span>
          <span v-else-if="item.pspPaymentReference">
            PSP: <kbd>{{ item.pspPaymentReference }}</kbd>
          </span>
          <v-tooltip v-else position="bottom">
            <template #activator="{ props }">
              <v-icon class="opacity-2" v-bind="props">error</v-icon>
            </template>
            <span>We couldn't fetch the transaction's PSP reference, please contact support</span>
          </v-tooltip>
        </template>
        <template #item.paymentMethod="{ item }">
          <v-skeleton-loader v-if="apiResults.transactions.state.loading" type="text" />
          <span v-else-if="apiResults.transactions.state.error">
            <v-tooltip position="bottom">
              <template #activator="{ props }">
                <v-icon class="opacity-2" v-bind="props">error</v-icon>
              </template>
              <span>Something went wrong, please refresh</span>
            </v-tooltip>
          </span>
          <span v-else-if="apiResults.transactions.data![item.pspPaymentReference]">
            <PaymentMethod v-model="apiResults.transactions.data![item.pspPaymentReference].paymentMethod" />
          </span>
          <span v-else>
            <v-tooltip position="bottom">
              <template #activator="{ props }">
                <v-icon class="opacity-2" v-bind="props">horizontal_rule</v-icon>
              </template>
              <span>Transfer not related to a transaction</span>
            </v-tooltip>
          </span>
        </template>
        <template #expanded-row="{ columns, item }">
          <tr>
            <td v-if="apiResults.transactions.data && apiResults.transactions.data[item.pspPaymentReference]" :colspan="columns.length" class="pa-4">
              <div v-if="apiResults.transactions.data[item.pspPaymentReference].paymentLinkId">
                <strong>Payment Link ID:</strong>&nbsp;
                <kbd>{{ apiResults.transactions.data[item.pspPaymentReference].paymentLinkId }}</kbd>
              </div>
              <div>
                <strong>Transaction ID:</strong>&nbsp;
                <kbd>{{ apiResults.transactions.data[item.pspPaymentReference].transactionId }}</kbd>
              </div>
              <div>
                <strong>Merchant Reference:</strong>
                {{ apiResults.transactions.data[item.pspPaymentReference].adyenMerchantReference }}
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </div>

  <v-btn-nd class="mt-5" to="/payouts">
    <template #prepend><v-icon class="opacity-10">mdi-arrow-left</v-icon></template>
    Payouts
  </v-btn-nd>
</template>

<script setup lang="ts">
import BigNumber from 'bignumber.js';
import { format, parseISO } from 'date-fns';
import { inject, computed, ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useDisplay } from 'vuetify';
import { Amount } from '@/api/merchant-service/common-types';
import * as Payout from '@/api/merchant-service/payout';
import { TransactionReducedV1 } from '@/api/merchant-service/transaction';
import { API } from '@/plugins/api';

const api = inject<API>('api');

const route = useRoute();
const { mdAndUp } = useDisplay();

const transactionsTableHeaders = ref([
  { title: 'Reference', value: 'pspPaymentReference' },
  { title: 'Type', value: 'type' },
  { title: 'Amount', value: 'amount' },
  { title: 'Tip', value: 'tip' },
  { title: 'Payment Method', value: 'paymentMethod' },
  { title: 'Date', value: 'bookingDate' },
]);
const typeFilters = ref(['transaction', 'refund', 'chargeback', 'internal transfer']);

const apiResults = ref<{
  payout: { data: Payout.PayoutLedger | undefined; state: { loading: boolean; error: boolean } };
  transactions: { data: Record<string, TransactionReducedV1> | undefined; state: { loading: boolean; error: boolean } };
}>({
  payout: { data: undefined, state: { loading: true, error: false } },
  transactions: { data: undefined, state: { loading: true, error: false } },
});

onMounted(async () => {
  apiResults.value.payout.state.loading = true;
  try {
    apiResults.value.payout.data = await api!.payout.getPayout(route.params.payoutId as string);
    apiResults.value.payout.data.transfers = apiResults.value.payout.data.transfers.map((t) => {
      return { ...t, day: t.bookingDate.split('T')[0] };
    });
  } catch (error) {
    console.error(error);
    apiResults.value.payout.state.error = true;
  } finally {
    apiResults.value.payout.state.loading = false;
  }
});

const selectedType = ref(undefined);
const selectedPaymentMethod = ref(undefined);
const filteredTotal = computed(() =>
  apiResults.value.payout
    .data!.transfers.filter((item) => filter(null, null, { value: undefined, raw: item }))
    .reduce((acc, item) => acc + item.amount.value + (item.includedTip?.value ?? 0) + (item.additionalTip?.value ?? 0), 0),
);
const paymentMethodFilters = computed(() => {
  if (!apiResults.value.transactions.data) {
    return [];
  }
  const days = Object.keys(apiResults.value.transactions.data);
  const paymentMethods = days.map((k) => apiResults.value.transactions.data![k].paymentMethod);
  return [...new Set(paymentMethods)];
});
const payoutCurrency = computed<string | undefined>(() => apiResults.value.payout.data?.amount.currency);
const search = computed(() => selectedType.value + ' ' + selectedPaymentMethod.value);
const dailySummary = computed<Payout.DailySummary[] | undefined>(() => {
  // We need to reformat the data sent to the DailySummary component because the API returns a different format...
  return apiResults.value.payout.data?.payoutSummary.map((day): Payout.DailySummary => {
    const createAmount = (value: number): Amount => ({ value: new BigNumber(value).dividedBy(100).toNumber(), currency: payoutCurrency.value! });
    return {
      date: day.date,
      totalProcessed: createAmount(day.totalProcessed),
      totalRefunded: createAmount(day.totalRefunded),
      totalChargeback: createAmount(day.totalChargeback),
      includedTip: day.includedTip ? createAmount(day.includedTip) : undefined,
      additionalTip: day.additionalTip ? createAmount(day.additionalTip) : undefined,
      totalMisc: createAmount(day.totalMisc),
      subtotal: createAmount(day.subtotal),
    };
  });
});
const deducted = computed(() =>
  apiResults.value.payout.data!.payoutSummary.reduce(
    (acc: number, item) => acc + (item.totalRefunded + item.totalChargeback + item.totalMisc - (item.additionalTip ?? 0)),
    0,
  ),
);

watch(
  () => apiResults.value.payout.data,
  async () => {
    apiResults.value.transactions.state.loading = true;
    const pspReferences = apiResults.value.payout.data!.transfers.filter((t) => t.pspPaymentReference).map((t) => t.pspPaymentReference);
    try {
      apiResults.value.transactions.data = (await api!.transaction.getTransactionsFromPsp(pspReferences)).reduce<Record<string, TransactionReducedV1>>(
        (acc, item) => {
          acc[item.pspReference] = item;
          return acc;
        },
        {},
      );
    } catch (error) {
      console.error(error);
      apiResults.value.transactions.state.error = true;
    } finally {
      apiResults.value.transactions.state.loading = false;
    }
  },
);

const filter = (value: unknown, query: unknown, item?: { value: unknown; raw: Payout.Transfer }) => {
  if (!item?.raw.type) return false;
  let keep = selectedType.value ? item.raw.type === selectedType.value : true;
  keep =
    keep && selectedPaymentMethod.value && apiResults.value.transactions.data
      ? apiResults.value.transactions.data[item.raw.pspPaymentReference] &&
        apiResults.value.transactions.data[item.raw.pspPaymentReference].paymentMethod === selectedPaymentMethod.value
      : keep;
  return keep;
};

const displayAmount = (amount: Amount | number) => {
  const _value = typeof amount === 'number' ? amount : amount.value;
  const _currency = typeof amount === 'number' ? payoutCurrency.value! : amount.currency;
  return new BigNumber(_value).dividedBy(100).toNumber().toLocaleString('en-UK', { style: 'currency', currency: _currency });
};

const displayTime = (time: string) => format(parseISO(time), 'yyy-MM-dd HH:mm');
</script>
