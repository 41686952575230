<template>
  <h2>Payouts</h2>

  <v-row>
    <v-col cols="12">
      <v-card title="Express payout" elevation="3" class="ma-3">
        <v-card-text>
          <p class="me-2 mb-2">Transfer all available balance to the transfer instrument</p>
          <v-select
            v-model="transferInstrumentIdSelected"
            :items="transferInstruments"
            item-title="accountIdentifier"
            item-value="id"
            label="Transfer instrument"
            clearable
            hide-details
            class="me-2 mb-2"
            min-width="220px"
          />
        </v-card-text>

        <v-card-actions class="pe-6">
          <v-spacer />
          <v-btn
            prepend-icon="mdi-cash-multiple"
            text="Transfer"
            class="bg-error on-error mb-2"
            :disabled="!transferInstrumentIdSelected"
            :loading="triggerTransferLoading"
            @click="triggerTransfer"
          />
        </v-card-actions>
      </v-card>
    </v-col>

    <v-divider />

    <v-col v-if="!settings?.length" cols="12"><v-alert type="warning">Company has no payout settings.</v-alert></v-col>

    <v-col v-for="(payoutConfig, index) in settings ?? []" :key="index" :cols="(settings ?? []).length > 1 && mdAndUp ? 6 : 12">
      <site-sweep-config :key="payoutConfig.id" :config="payoutConfig" :transfer-instruments="transferInstruments" :site-id="siteId" />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { captureException } from '@sentry/vue';
import { BalanceAccountPayoutConfig, GetSiteSettings200ResponseTransferInstrumentsInner } from '@tableyeti/merchant-service';
import { AxiosError } from 'axios';
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { useApplicationStore } from '@/store/application';

const { siteId } = defineProps<{
  siteId: string;
  settings?: BalanceAccountPayoutConfig[];
  transferInstruments?: GetSiteSettings200ResponseTransferInstrumentsInner[];
}>();

const { api, currentMerchantId } = useYetipayApi();
const applicationStore = useApplicationStore();
const { mdAndUp } = useDisplay();

const transferInstrumentIdSelected = ref<string | undefined>(undefined);
const triggerTransferLoading = ref(false);
const triggerTransfer = async () => {
  triggerTransferLoading.value = true;
  try {
    await api.triggerTransfer(currentMerchantId, siteId, { transferInstrumentId: transferInstrumentIdSelected.value! });
    applicationStore.notifyUser({ message: 'Transfer triggered' });
  } catch (error) {
    console.error('Failed to trigger transfer', error);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const message = ((error as AxiosError).response?.data as any)?.error ?? 'Unknown error';
    applicationStore.notifyUser({ message: `Failed to trigger transfer: ${message}`, type: 'error' });
    captureException(error);
  } finally {
    triggerTransferLoading.value = false;
  }
};
</script>
