<template>
  <v-card class="py-8 px-6 text-center mx-auto ma-4" elevation="12" max-width="400" width="100%">
    <h3 class="text-h6 mb-4">Connect your device</h3>
    <div v-if="!assignSuccess">
      <div class="text-body-2">Choose the site from the dropdown menu, then enter the pin showing on the device screen to connect it to your site</div>
      <v-alert v-if="submitError" class="mt-6 mb-6" type="error">
        {{ submitError }}
      </v-alert>
      <SiteSelector v-model="selectedSite" :disabled="loadingData" />
      <v-otp-input v-model="model.otp" :disabled="loadingData" autocomplete="off" required></v-otp-input>
      <v-btn :loading="loadingData" :disabled="v$.$invalid" @click="assignDevice">Connect</v-btn>
    </div>
    <div v-else>
      <v-alert type="info" class="text-sm-left mb-6">
        The device has been successfully connected to the site. Please wait for the initialization to complete
      </v-alert>
      <v-btn @click="router.push(`/devices?selectedSite=${selectedSite}`)">Back to devices</v-btn>
    </div>
    <v-card-subtitle class="mt-6 text-left pl-0">
      Need help? <a href="https://help.yetipay.me/en/articles/10221550-connecting-my-device" target="_blank">Click here</a>
    </v-card-subtitle>
  </v-card>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core';
import { integer, maxLength, minLength, required } from '@vuelidate/validators';
import { inject, onMounted, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { AssignDevice } from '@/api/merchant-service/device';
import { API } from '@/plugins/api';
import router from '@/router';
import { requiredField } from '@/utils/custom-validators';

const api = inject<API>('api');
const route = useRoute();

const selectedSite = ref<string | undefined>(undefined);
const loadingData = ref(false);
const submitError = ref<string | undefined>(undefined);
const assignSuccess = ref(false);

onMounted(() => {
  if (route.query.selectedSite) {
    selectedSite.value = route.query.selectedSite as string;
  }
});

const model = ref<AssignDevice & { selectedSite: Ref<string | undefined> }>({
  otp: '',
  selectedSite,
});

const v$ = useVuelidate(
  {
    otp: {
      $autoDirty: true,
      requiredField,
      minLength: minLength(6),
      maxLength: maxLength(6),
      integer,
    },
    selectedSite: {
      $autoDirty: true,
      required,
    },
  },
  model,
);

const assignDevice = async () => {
  if (!(await v$.value.$validate())) {
    return;
  }
  try {
    loadingData.value = true;
    await api?.device.assignDevice(selectedSite.value!, {
      otp: model.value.otp,
    });
    assignSuccess.value = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.response?.status === 404) {
      submitError.value = 'Device not found for submitted OTP';
      return;
    }
    const message = error.response?.data?.error;
    submitError.value = message ?? 'An error ocurred when processing this request';
    return;
  } finally {
    loadingData.value = false;
  }
};
</script>
