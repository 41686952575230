<template>
  <div :class="mobile ? 'mx-3' : 'mx-15'" align="center">
    <div style="max-width: 500px">
      <div v-if="isPageLoading" class="d-flex justify-center"><v-progress-circular indeterminate class="on-secondary" :size="50" /></div>

      <div v-else-if="currentStep == onboardingSteps.COMPANY_TYPE" class="on-secondary" align="center">
        <h1 class="d-flex justify-center">Onboarding</h1>
        <p class="d-flex justify-center mt-5">Welcome to yetipay.me!</p>
        <p class="d-flex justify-center mt-15">Are you onboarding as a company or as a sole proprietor?</p>
        <p class="d-flex justify-center mt-10"><v-btn-var width="230px" @click="setEntity('company')">Company</v-btn-var></p>
        <p class="d-flex justify-center mt-10"><v-btn-var width="230px" @click="setEntity('sole-trader')">Sole proprietor</v-btn-var></p>
      </div>

      <div v-else>
        <p class="d-flex on-secondary justify-center my-3">Tell us the basics about your company, then we'll proceed to legal verification checks.</p>

        <v-alert v-if="onLoadGenerateLinkFailure" icon="mdi-alert" border="top" color="error" class="my-3" dark>
          Issue when initialising onboarding. Please refresh and if this issue persists then contact TableYeti support.
        </v-alert>

        <v-card v-else :class="mobile ? 'pa-6' : 'pa-5'" align="start">
          <v-window :model-value="currentStep" :touch="false">
            <v-window-item :value="onboardingSteps.COMPANY_DETAILS" class="px-sm-10">
              <p>
                <span class="text-h4">1 of 4 </span>
                <span>{{ stepOneForm.entity === 'company' ? 'Your company' : 'About you' }}</span>
              </p>
              <br />
              <v-form>
                <p>
                  {{ stepOneForm.entity === 'company' ? 'What is the legal entity name of your company?' : 'What is your legal entity name?' }}
                </p>
                <v-text-field
                  v-model.trim="stepOneForm.legalEntityName"
                  class="pb-5"
                  label="Legal entity name"
                  :rules="validationErrors(v$.stepOneForm.legalEntityName.$errors)"
                  required
                />

                <div v-if="stepOneForm.entity === 'company'">
                  <p>What is the registration number of your company?</p>
                  <v-text-field
                    v-model.trim="stepOneForm.registrationNumber"
                    class="pb-5"
                    label="Registration number"
                    :rules="validationErrors(v$.stepOneForm.registrationNumber.$errors)"
                    :error-messages="stepOneSubmitError"
                    required
                  />
                </div>

                <div v-if="stepOneForm.entity === 'sole-trader'">
                  <p>What is your first name?</p>
                  <v-text-field
                    v-model.trim="stepOneForm.firstName"
                    class="pb-5"
                    label="First name"
                    :rules="validationErrors(v$.stepOneForm.firstName.$errors)"
                    required
                  />

                  <p>What is your last name?</p>
                  <v-text-field
                    v-model.trim="stepOneForm.lastName"
                    class="pb-5"
                    label="Last name"
                    :rules="validationErrors(v$.stepOneForm.lastName.$errors)"
                    required
                  />
                </div>

                <p>
                  {{
                    stepOneForm.entity === 'company' ? "Which country is your company's registered address in?" : 'What country is your registered address in?'
                  }}
                </p>
                <v-select
                  v-model="stepOneForm.country"
                  class="pb-5"
                  :items="tradedCountries"
                  label="Country"
                  item-title="name"
                  item-value="code"
                  :rules="validationErrors(v$.stepOneForm.country.$errors)"
                  required
                />

                <p>What industry is your company in?</p>
                <v-autocomplete
                  v-model="stepOneForm.industryCode"
                  class="pb-5"
                  :items="industryCodes"
                  item-title="name"
                  item-value="value"
                  label="Industry"
                  :rules="validationErrors(v$.stepOneForm.industryCode.$errors)"
                  required
                />
              </v-form>
              <div class="d-flex">
                <v-btn-nd @click="swapEntity">Prev</v-btn-nd>
                <v-btn class="ms-auto" :disabled="v$.stepOneForm.$invalid" @click="submitStepOne">Next</v-btn>
              </div>
            </v-window-item>

            <v-window-item :value="onboardingSteps.PAYMENT_METHODS" class="px-sm-10">
              <p><span class="text-h4">2 of 4 </span> Payments</p>
              <br />
              <v-form>
                <p>What payment methods do you need to support?</p>
                <span>Payment methods (visa/mastercard/amex etc.)</span>
                <v-select
                  v-model="stepTwoForm.selectedPaymentMethods"
                  :items="tradedPaymentMethods"
                  label="Select payment methods"
                  item-title="name"
                  item-value="code"
                  :rules="validationErrors(v$.stepTwoForm.selectedPaymentMethods.$errors)"
                  multiple
                  required
                >
                  <template #item="{ props, item }">
                    <v-list-item v-bind="props" :disabled="item.raw.mandatory"></v-list-item>
                  </template>
                </v-select>
              </v-form>

              <div class="d-flex">
                <v-btn-nd @click="currentStep = onboardingSteps.COMPANY_DETAILS">Prev</v-btn-nd>
                <v-btn class="ms-auto" :disabled="v$.stepTwoForm.$invalid" @click="currentStep = onboardingSteps.SITE">Next</v-btn>
              </div>
            </v-window-item>

            <v-window-item :value="onboardingSteps.SITE" class="px-sm-10">
              <p><span class="text-h4">3 of 4 </span> Your first site</p>
              <br />
              <SiteForm
                v-model="stepThreeForm"
                mode="create"
                :is-primary-loading="isLoading"
                :primary-error="createMerchantError"
                :merchant-country="stepOneForm.country"
                primary-text-override="Next"
                :show-secondary="true"
                @primary="createMerchant"
                @secondary="() => (currentStep = onboardingSteps.PAYMENT_METHODS)"
              />
            </v-window-item>

            <v-window-item :value="onboardingSteps.VERIFICATION" class="px-sm-10">
              <p><span class="text-h4">4 of 4 </span> Verification checks</p>
              <p>The final step is the legal verification checks. Make sure you've got the below documentation to hand to avoid delays during verification.</p>
              <br />
              <ul class="ml-5">
                <li>
                  <p>Photo ID documents of all beneficial owners associated with the legal entity organisation.</p>
                </li>
              </ul>
              <br />
              <p>If you're ready to start the verification process, click Start. Don't worry, we'll save your progress so you can pick it back up anytime.</p>
              <br />
              <div class="text-end">
                <v-btn class="ms-auto" :loading="loadingHostedOnboardingUrl" :disabled="loadingHostedOnboardingUrl" @click="startKyc">Start</v-btn>
              </div>
            </v-window-item>

            <v-window-item v-if="selectedIndustry" :value="onboardingSteps.INDUSTRY_PENDING_VALIDATION" class="px-sm-10">
              <p class="text-h4">Hold tight</p>
              <p v-if="industryValidateError">
                The industry you selected ({{ selectedIndustry.name }}) requires additional validation. Please contact your account manager or email yetipay.me
                support at <a href="mailto:help@yetipay.me">help@yetipay.me</a>.
              </p>
              <p v-else>
                We need to do some additional checks based on the industry you selected ({{ selectedIndustry.name }}). We'll email you once the additional
                checks are completed to let you know you can continue your onboarding journey. If you have any questions, contact your account manager or email
                yetipay.me support at <a href="mailto:help@yetipay.me">help@yetipay.me</a>.
              </p>
              <p>
                If you've selected the wrong industry type and need to update it, click
                <a href="" @click.prevent="currentStep = onboardingSteps.UPDATE_INDUSTRY">here</a>.
              </p>
            </v-window-item>

            <v-window-item v-if="selectedIndustry" :value="onboardingSteps.UPDATE_INDUSTRY" class="px-sm-10">
              <p class="text-h4">Update industry</p>
              <p>Once you've updated your industry, you'll be able to continue your onboarding journey.</p>
              <p>What industry is your company in?</p>
              <form>
                <v-autocomplete
                  v-model="stepOneForm.industryCode"
                  class="pb-5"
                  :items="industryCodes"
                  item-title="name"
                  item-value="value"
                  label="Industry"
                  :rules="validationErrors(v$.stepOneForm.industryCode.$errors)"
                  required
                ></v-autocomplete>
              </form>
              <p class="text-red">
                By clicking submit, you confirm that the industry you've selected accurately represents your business to the best of your knowledge.
              </p>
              <div class="text-end">
                <v-btn class="ms-auto" :disabled="v$.stepOneForm.$invalid" @click="submitStepOne">Submit</v-btn>
              </div>
            </v-window-item>
          </v-window>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ErrorObject, useVuelidate } from '@vuelidate/core';
import { required, minLength, requiredIf } from '@vuelidate/validators';
import { ref, inject, computed, watch, onBeforeMount, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import { CreateMerchantRequest, Industry } from '@/api/merchant-service/merchant';
import { Site } from '@/api/merchant-service/site';
import { API } from '@/plugins/api';
import { useAuthenticationStore } from '@/store/authentication';
import { useMerchantsStore } from '@/store/merchants';
import { tradedCountries, tradedPaymentMethods } from '@/utils/config';

const OnboardingSteps = {
  COMPANY_TYPE: 'COMPANY_TYPE',
  COMPANY_DETAILS: 'COMPANY_DETAILS',
  PAYMENT_METHODS: 'PAYMENT_METHODS',
  SITE: 'SITE',
  VERIFICATION: 'VERIFICATION',
  INDUSTRY_PENDING_VALIDATION: 'INDUSTRY_PENDING_VALIDATION',
  UPDATE_INDUSTRY: 'UPDATE_INDUSTRY',
};

const api = inject<API>('api');
const merchantsStore = useMerchantsStore();
const authStore = useAuthenticationStore();
const { mobile } = useDisplay();
const route = useRoute();
const router = useRouter();

const currentUserEmail = ref<string | undefined>(undefined);
const isPageLoading = ref(true);
const stepOneForm = ref({
  entity: '',
  legalEntityName: '',
  firstName: '',
  lastName: '',
  registrationNumber: '',
  industryCode: '',
  country: '',
});
const stepTwoForm = ref({
  selectedPaymentMethods: ['visa', 'mc'],
});
const stepThreeForm = ref<Site>({
  name: '',
  phoneNumber: '',
  address: {
    line1: '',
    city: '',
    postalCode: '',
    country: '',
  },
});
const isLoading = ref(false);
const hostedOnboardingUrl = ref<string | undefined>(undefined);
const loadingHostedOnboardingUrl = ref(false);
const onLoadGenerateLinkFailure = ref(false);
const createMerchantError = ref<string | undefined>(undefined);
const stepOneSubmitError = ref<string | null>(null);
const industryCodes = ref<Industry[]>([]);
const industryValidateError = ref<string | null>(null);
const currentStep = ref(OnboardingSteps.COMPANY_TYPE);
const onboardingSteps = OnboardingSteps;

const v$ = useVuelidate(
  {
    stepOneForm: {
      entity: { $autoDirty: true, required },
      legalEntityName: { $autoDirty: true, required },
      registrationNumber: {
        $autoDirty: true,
        required: requiredIf(() => stepOneForm.value.entity === 'company'),
      },
      firstName: {
        $autoDirty: true,
        required: requiredIf(() => stepOneForm.value.entity === 'sole-trader'),
      },
      lastName: {
        $autoDirty: true,
        required: requiredIf(() => stepOneForm.value.entity === 'sole-trader'),
      },
      country: { $autoDirty: true, required },
      industryCode: { $autoDirty: true, required },
    },
    stepTwoForm: {
      selectedPaymentMethods: { $autoDirty: true, required, minLength: minLength(2) },
    },
  },
  {
    stepOneForm,
    stepTwoForm,
  },
);

const selectedIndustry = computed<Industry | undefined>(() => industryCodes.value.find((industry) => industry.value === stepOneForm.value.industryCode));
const localStorageKey = computed(() => `${currentUserEmail.value}:partialMerchantRequest`);

onBeforeMount(() => {
  if (!merchantsStore.currentMerchant && !route.query.deal) {
    router.push('/onboarding');
  }
});

onMounted(async () => {
  industryCodes.value = await api!.merchant.fetchIndustryCodes();

  if (merchantsStore.currentMerchant) {
    if (merchantsStore.isCurrentMerchantVerified) {
      router.push('/');
      return;
    } else {
      currentStep.value = OnboardingSteps.VERIFICATION;
    }
  } else {
    currentUserEmail.value = authStore.user.email;
    const partialRequest = localStorage.getItem(localStorageKey.value);
    if (partialRequest) {
      const request = JSON.parse(partialRequest);
      if (request.stepOneForm) {
        stepOneForm.value = request.stepOneForm;
      }
      if (request.stepTwoForm) {
        stepTwoForm.value = request.stepTwoForm;
      }
      if (request.stepThreeForm) {
        stepThreeForm.value = request.stepThreeForm;
      }
      if (selectedIndustry.value?.status !== 'APPROVED') {
        currentStep.value = OnboardingSteps.INDUSTRY_PENDING_VALIDATION;
      } else {
        currentStep.value = OnboardingSteps.COMPANY_DETAILS;
      }
    }
  }

  isPageLoading.value = false;
});

const maybeFetchKycLink = async () => {
  if (hostedOnboardingUrl.value) {
    const expiry = new URLSearchParams(hostedOnboardingUrl.value).get('expiry');
    if (expiry) {
      const expiryDate = new Date(parseInt(expiry));
      expiryDate.setSeconds(expiryDate.getSeconds() - 5);
      if (expiryDate > new Date()) {
        // No need to generate a new link
        return;
      }
    }
  }

  loadingHostedOnboardingUrl.value = true;
  try {
    await merchantsStore.initialize(false);
    const result = await api!.merchant.requestOnboardingLink(merchantsStore.currentMerchant!.merchantId);
    hostedOnboardingUrl.value = result;
  } catch (error) {
    console.error(error);
    onLoadGenerateLinkFailure.value = true;
  } finally {
    loadingHostedOnboardingUrl.value = false;
  }
};

const startKyc = async () => {
  await maybeFetchKycLink();
  if (hostedOnboardingUrl.value) {
    window.open(hostedOnboardingUrl.value, '_self');
  }
};

const submitStepOne = async () => {
  if (selectedIndustry.value?.status !== 'APPROVED') {
    currentStep.value = OnboardingSteps.INDUSTRY_PENDING_VALIDATION;
    try {
      await api!.merchant.requestIndustryValidation(stepOneForm.value.industryCode, stepOneForm.value.legalEntityName);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      industryValidateError.value = error;
    }
  } else {
    currentStep.value = OnboardingSteps.PAYMENT_METHODS;
  }
};

const createMerchant = async () => {
  createMerchantError.value = undefined;
  stepOneSubmitError.value = null;
  isLoading.value = true;
  const request: CreateMerchantRequest = {
    entity: stepOneForm.value.entity as 'sole-trader' | 'company',
    legalEntityName: stepOneForm.value.legalEntityName,
    registrationNumber: stepOneForm.value.entity === 'company' ? stepOneForm.value.registrationNumber : undefined,
    industryCode: stepOneForm.value.industryCode,
    individual: stepOneForm.value.entity === 'sole-trader' ? { firstName: stepOneForm.value.firstName, lastName: stepOneForm.value.lastName } : undefined,
    registeredAddress: {
      country: stepOneForm.value.country,
    },
    selectedPaymentMethods: stepTwoForm.value.selectedPaymentMethods,
    site: {
      name: stepThreeForm.value.name,
      phoneNumber: stepThreeForm.value.phoneNumber,
      address: {
        line1: stepThreeForm.value.address.line1,
        line2: stepThreeForm.value.address.line2,
        city: stepThreeForm.value.address.city,
        postalCode: stepThreeForm.value.address.postalCode,
        country: stepThreeForm.value.address.country,
      },
      shortCode: stepThreeForm.value.shortCode,
      payByLinkTermsUrl: stepThreeForm.value.payByLinkTermsUrl,
    },
    dealReference: route.query['deal'] as string,
  };

  await merchantsStore.initialize(false);
  if (merchantsStore.currentMerchant && !merchantsStore.isCurrentMerchantVerified) {
    // remove the second attempt, that now is causing an infinite redirect to step-1
    localStorage.removeItem(localStorageKey.value);
    router.push('/onboarding');
  }
  const createMerchantResult = await api!.merchant.createMerchant(request);
  // TODO: save created merchant to merhant store
  if (createMerchantResult.hostedOnboardingLink) {
    hostedOnboardingUrl.value = createMerchantResult.hostedOnboardingLink;
    currentStep.value = OnboardingSteps.VERIFICATION;
    isLoading.value = false;
    localStorage.removeItem(localStorageKey.value);
  } else {
    if (createMerchantResult.errorMessage === 'Invalid legal entity information provided') {
      currentStep.value = OnboardingSteps.COMPANY_DETAILS;
      stepOneSubmitError.value = 'Company number is invalid';
    } else {
      createMerchantError.value = createMerchantResult.errorMessage;
    }
    isLoading.value = false;
  }
};

watch(
  () => ({ stepOneForm, stepTwoForm, stepThreeForm }),
  (value, oldValue) => {
    if (oldValue.stepOneForm.value.registrationNumber !== value.stepOneForm.value.registrationNumber) {
      // If the user modify the problematic field, we remove the error message
      stepOneSubmitError.value = null;
    }
    localStorage.setItem(
      localStorageKey.value,
      JSON.stringify({
        stepOneForm: stepOneForm.value,
        stepTwoForm: stepTwoForm.value,
        stepThreeForm: stepThreeForm.value,
      }),
    );
  },
  { deep: true },
);

const setEntity = (value: string) => {
  stepOneForm.value.entity = value;
  currentStep.value = OnboardingSteps.COMPANY_DETAILS;
};

const swapEntity = () => {
  if (stepOneForm.value.entity === 'company') {
    stepOneForm.value.registrationNumber = '';
  } else {
    stepOneForm.value.firstName = '';
    stepOneForm.value.lastName = '';
  }
  currentStep.value = OnboardingSteps.COMPANY_TYPE;
};

const validationErrors = (errors: ErrorObject[]) => errors.map((e) => e.$message.toString());
</script>
