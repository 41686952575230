<template>
  <div v-if="!route.query.deal" class="mt-10 justify-center mx-3" align="center">
    <v-card class="pa-10 text-primary rounded-lg" style="max-width: 500px">
      <h1>Oops! Looks like you've signed up without using your custom onboarding link.</h1>
      <p class="mt-5">
        To continue onboarding, you'll need your custom link. Check your email, and if you can't find your link, you can either speak to your sales rep or
        contact Yetipay onboarding support at <a href="mailto:help@yetipay.me">help@yetipay.me</a>.
      </p>
    </v-card>
  </div>

  <div v-else-if="hasLoaded" class="on-secondary" align="center">
    <h1 class="d-flex justify-center">Welcome to Basecamp!</h1>
    <p class="d-flex justify-center">Tools from yetipay.me for managing your suite of payment devices.</p>
    <p class="d-flex justify-center mt-15"><v-btn-var @click="navigateToWizard">start onboarding</v-btn-var></p>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
import { useAuthenticationStore } from '@/store/authentication';
import { useMerchantsStore } from '@/store/merchants';

const route = useRoute();
const router = useRouter();
const merchantsStore = useMerchantsStore();
const authStore = useAuthenticationStore();

onBeforeRouteUpdate((to, from, next) => {
  if (merchantsStore.currentMerchant && !merchantsStore.isCurrentMerchantVerified) {
    next('/onboarding/wizard');
  }
});

const hasLoaded = ref(false);
onMounted(() => {
  const partialRequest = localStorage.getItem(`${authStore.user.email}:partialMerchantRequest`);
  if (partialRequest && !!route.query.deal) {
    navigateToWizard();
  } else {
    hasLoaded.value = true;
  }
});

const navigateToWizard = () => router.push('/onboarding/wizard' + route.fullPath.replace(route.path, '')); // preserve query params;
</script>
