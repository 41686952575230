<template>
  <v-card :subtitle="`ID: ${sweepDetails.id}`" elevation="3" class="ma-3">
    <template #title>
      <div class="d-flex flex-wrap align-center justify-space-between">
        <v-chip class="mb-1" :color="isSweepActive ? 'green' : 'red'">Status: {{ sweepDetails.status?.toUpperCase() }}</v-chip>
        <v-btn
          prepend-icon="mdi-alert"
          :class="(isSweepActive ? 'bg-success' : 'bg-error') + ' mb-1'"
          :loading="isLoading"
          @click="updateSweep({ status: isSweepActive ? 'inactive' : 'active' })"
        >
          {{ isSweepActive ? 'Deactivate' : 'Reactivate' }}
        </v-btn>
      </div>
    </template>
    <v-card-text>
      <p class="text-capitalize"><span class="font-weight-bold">Type:</span> {{ sweepDetails.category }} transfer</p>
      <p><span class="font-weight-bold">Next Payout:</span> {{ nextRunDate }}</p>
      <p><span class="font-weight-bold">Currency:</span> {{ sweepDetails.currency }}</p>
      <p><span class="font-weight-bold">Bank Reference:</span> {{ sweepDetails.description }}</p>
    </v-card-text>
    <v-card-text>
      <v-form
        v-if="transferInstruments.length"
        :class="!xs && 'd-flex align-center justify-space-between'"
        @submit.prevent="updateSweep({ counterparty: { transferInstrumentId: newCounterparty } })"
      >
        <v-select
          v-model="newCounterparty"
          :items="transferInstruments"
          item-title="accountIdentifier"
          item-value="id"
          max-width="270"
          label="Payout Bank Account"
          hide-details
          outlined
        >
          <template #item="{ props, item }">
            <v-list-item v-bind="props" :subtitle="item.raw.id" />
          </template>
        </v-select>
        <v-btn v-if="newCounterparty !== sweepDetails.counterparty" :block="xs" :class="xs && 'mt-3'" color="primary" type="submit" :loading="isLoading">
          Update
        </v-btn>
      </v-form>
      <div v-else>Merchant has no active transfer instruments.</div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { captureException } from '@sentry/vue';
import { BalanceAccountPayoutConfig, GetSiteSettings200ResponseTransferInstrumentsInner } from '@tableyeti/merchant-service';
import parser from 'cron-parser';
import { format } from 'date-fns';
import { defineProps, ref, computed } from 'vue';
import { useDisplay } from 'vuetify';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { useApplicationStore } from '@/store/application';

const { config, transferInstruments, siteId } = defineProps<{
  config: BalanceAccountPayoutConfig;
  transferInstruments: GetSiteSettings200ResponseTransferInstrumentsInner[];
  siteId: string;
}>();

const { api, currentMerchantId } = useYetipayApi();
const { xs } = useDisplay();

const isLoading = ref<boolean>(false);
const sweepDetails = ref<BalanceAccountPayoutConfig>(config);

const nextRunDate = computed(() => {
  if (!config.schedule.cronExpression) return undefined;

  const date = new Date(parser.parseExpression(config.schedule.cronExpression).next().toString());
  return `(${format(date, 'EEEE')}) ${date.toLocaleString()}`;
});

const isSweepActive = computed(() => sweepDetails.value.status === 'active');
const newCounterparty = ref<string | undefined>(transferInstruments.find((instrument) => instrument.id === sweepDetails.value.counterparty)?.id);

const updateSweep = async (update: object) => {
  isLoading.value = true;
  try {
    sweepDetails.value = await api.updateSweepSettings(currentMerchantId, siteId, config.id, update).then((response) => response.data.data);
    useApplicationStore().notifyUser({ message: 'Payout config update', type: 'success' });
  } catch (error) {
    captureException(error);
    useApplicationStore().notifyUser({ message: 'Failed to update payout config', type: 'error' });
  } finally {
    isLoading.value = false;
  }
};
</script>
