<template>
  <div class="device-slogan">
    <v-skeleton-loader v-if="!props.slogan" type="list-item" min-width="100%" />
    <div v-else>
      <div v-if="!isEditing" class="hover-container" @mouseover="isHovering = true" @mouseleave="isHovering = false" @blur="isHovering = false">
        {{ localSlogan }}
        <v-icon :class="{ hidden: !isHovering && !mobile }" class="edit-slogan-btn" color="secondary" @click="isEditing = true">
          mdi-pencil-circle-outline
        </v-icon>
      </div>
      <div v-else>
        <v-form @submit.prevent="saveSlogan">
          <v-text-field
            v-model="localSlogan"
            autofocus
            density="compact"
            :hide-details="true"
            variant="solo"
            maxlength="25"
            @mouseleave="isHovering = false"
            @blur="saveSlogan"
            @keydown.enter="saveSlogan"
          />
        </v-form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, inject, ref, watch } from 'vue';
import { useDisplay } from 'vuetify';
import { API } from '@/plugins/api';
import { useApplicationStore } from '@/store/application';

const appStore = useApplicationStore();
const props = defineProps<{ deviceId: string; siteId: string; slogan: string | undefined }>();
const api = inject<API>('api');
const { mobile } = useDisplay();

const isHovering = ref(false);
const isEditing = ref(false);
const localSlogan = ref(props.slogan);

watch(
  () => props.slogan,
  (newSlogan) => {
    localSlogan.value = newSlogan;
  },
);

const saveSlogan = async () => {
  isEditing.value = false;
  isHovering.value = false;
  if (localSlogan.value !== props.slogan && localSlogan.value) {
    try {
      await api!.device.updateDeviceConfig(props.siteId, props.deviceId, { signature: { deviceSlogan: localSlogan.value } });
      appStore.notifyUser({ message: 'Device updated successfully', type: 'success' });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      localSlogan.value = props.slogan;
      appStore.notifyUser({ message: 'Failed to update device', type: 'error' });
    }
  }
};
</script>

<style scoped>
.hover-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media (max-width: 768px) {
  .hover-container {
    justify-content: flex-end;
  }
}
.edit-slogan-btn {
  cursor: pointer;
  margin-left: 5px;
}
.hidden {
  visibility: hidden;
}
</style>
