<template>
  <h2>Payment Methods</h2>

  <v-card elevation="3" class="ma-3 pa-3" width="600">
    <v-table>
      <thead>
        <tr>
          <th class="text-left font-weight-bold">Name</th>
          <th class="text-left font-weight-bold">Currencies</th>
          <th class="text-left font-weight-bold">Verification Status</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="method in paymentMethods" :key="method.id">
          <td>{{ method.name?.replace('_', ' ') }}</td>
          <td>{{ method.currencies?.join(', ') }}</td>
          <td>{{ method.verificationStatus ?? 'N/A' }}</td>
          <td>
            <v-btn
              density="compact"
              :loading="method.updateLoading"
              :class="method.enabled ? 'bg-error' : 'bg-success'"
              @click="togglePaymentMethodStatus(method.id)"
            >
              {{ method.enabled ? 'Disable' : 'Enable' }}
            </v-btn>
          </td>
        </tr>

        <tr>
          <td colspan="3"><v-autocomplete v-model="paymentMethodToAdd" hide-details density="compact" :items="paymentMethodsPossibleToAdd" clearable /></td>
          <td class="d-flex justify-end align-center">
            <v-btn density="compact" :disabled="!paymentMethodToAdd" :loading="addPaymentMethodLoading" @click="addPaymentMethod">add</v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
  </v-card>
</template>

<script setup lang="ts">
import { captureException } from '@sentry/vue';
import { AddSitesPaymentMethodRequestPaymentMethodEnum, GetSiteSettings200ResponsePaymentMethodsInner } from '@tableyeti/merchant-service';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { useApplicationStore } from '@/store/application';

const { siteId, paymentMethods } = defineProps<{
  siteId: string;
  paymentMethods?: ({ updateLoading?: boolean } & GetSiteSettings200ResponsePaymentMethodsInner)[];
}>();

const { api, currentMerchantId } = useYetipayApi();
const applicationStore = useApplicationStore();
const router = useRouter();

const togglePaymentMethodStatus = async (paymentMethodId: string) => {
  const paymentMethod = paymentMethods!.find((method) => method.id === paymentMethodId);
  if (!paymentMethod) {
    applicationStore.notifyUser({ message: `Failed to toggle update method ${paymentMethodId} status`, type: 'error' });
    return;
  }
  paymentMethod.updateLoading = true;
  try {
    await api.updateSitesPaymentMethod(currentMerchantId, siteId, paymentMethodId, { enabled: !paymentMethod.enabled });
    paymentMethod.enabled = !paymentMethod.enabled;
    applicationStore.notifyUser({ message: `Payment method ${paymentMethod.name} ${paymentMethod.enabled ? 'enabled' : 'disabled'}` });
  } catch (error) {
    console.error(`Failed to toggle payment method ${paymentMethod.name} status`, error);
    applicationStore.notifyUser({ message: `Failed to toggle update method ${paymentMethod.name} status`, type: 'error' });
    captureException(error);
  } finally {
    paymentMethod.updateLoading = false;
  }
};

const paymentMethodsPossibleToAdd = computed(() => {
  if (!paymentMethods) {
    return [];
  }
  return Object.values(AddSitesPaymentMethodRequestPaymentMethodEnum).filter((method) => !paymentMethods!.find((m) => m.name === method));
});
const paymentMethodToAdd = ref<AddSitesPaymentMethodRequestPaymentMethodEnum | undefined>(undefined);
const addPaymentMethodLoading = ref(false);
const addPaymentMethod = async () => {
  if (!paymentMethodToAdd.value) {
    return;
  }
  addPaymentMethodLoading.value = true;
  try {
    await api.addSitesPaymentMethod(currentMerchantId, siteId, { paymentMethod: paymentMethodToAdd.value });
    applicationStore.notifyUser({ message: `Payment method ${paymentMethodToAdd.value} added` });
    router.push('/sites');
  } catch (error) {
    console.error('Failed to add payment method', error);
    applicationStore.notifyUser({ message: 'Failed to add payment method', type: 'error' });
    captureException(error);
  } finally {
    addPaymentMethodLoading.value = false;
  }
};
</script>
