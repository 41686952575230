<template>
  <v-text-field
    :model-value="modelValue"
    :error-messages="modelValue == undefined || isNaN(modelValue) ? 'Invalid number' : undefined"
    label="Credit"
    class="ma-2"
    @update:model-value="(input) => emit('update:modelValue', isNaN(parseFloat(input)) ? undefined : parseFloat(input))"
  />
</template>

<script setup lang="ts">
const { modelValue } = defineProps<{ modelValue?: number }>();
const emit = defineEmits(['update:modelValue']);
</script>
