<template>
  <BillingEmail v-if="auth.hasCompanyScoped(Permission.Company_UpdateDetails)" class="my-4" />

  <v-data-table :items="invoices" :headers="headers" :loading="loading" :mobile="tableMobile">
    <template #top>
      <div class="d-flex flex-row">
        <VueDatePicker v-model="month" month-picker placeholder="Select period" style="max-width: 165px" />
        <v-text-field
          v-model.trim="invoiceNumber"
          clearable
          label="Invoice number"
          placeholder="Search invoice"
          density="compact"
          class="ms-3"
          style="max-width: 185px"
        />
      </div>
    </template>

    <template #item.status="{ item }">
      <v-chip class="text-capitalize" :color="statusChipColor(item.status)" label>
        {{ item.status }}
      </v-chip>
    </template>
    <template #item.billingPeriod="{ item }">
      {{ format(item.billingPeriod, 'LLLL yyyy') }}
    </template>
    <template #item.amount="{ item }">
      {{ displayAmount(item.amount) }}
    </template>
    <template #item.dueDate="{ item }">
      {{ format(item.dueDate, 'dd/MM/yyyy') }}
    </template>
    <template #item.invoiceNumber="{ item }">
      <kbd>{{ item.invoiceNumber }}</kbd>
    </template>
    <template #item.actions="{ item }">
      <a v-if="item.pdfLink" :href="item.pdfLink"><v-icon size="14" color="secondary">mdi-download</v-icon></a>
      <v-icon v-else size="14" color="grey">mdi-download</v-icon>
    </template>

    <template #bottom>
      <InfinitePagination v-model:current-page="currentPage" :last-key-pages="lastKeyPages" />
    </template>
  </v-data-table>
</template>

<script setup lang="ts">
import { ListInvoices200ResponseItemsInner, ListInvoices200ResponseItemsInnerStatusEnum } from '@tableyeti/merchant-service';
import { addMonths, subMonths, endOfYear, format, startOfYear } from 'date-fns';
import { ref, computed, onMounted, watch } from 'vue';
import { useDisplay } from 'vuetify';
import { useYetipayApi } from '@/composables/useYetipayApi';
import { useAuthorizationStore, Permission } from '@/store/authorization';
import { displayAmount } from '@/utils/format';

const { width } = useDisplay();
const auth = useAuthorizationStore();
const { api, currentMerchantId } = useYetipayApi();

const invoices = ref<ListInvoices200ResponseItemsInner[] | undefined>([]);
const lastKeyPages = ref<(string | undefined)[]>([undefined]);
const currentPage = ref(1);
const month = ref<{ month: number; year: number } | undefined>();
const invoiceNumber = ref<string | undefined>();
const loading = ref(false);

const headers = [
  { title: '', value: 'status' },
  { title: 'Period', value: 'billingPeriod' },
  { title: 'Amount', value: 'amount' },
  { title: 'Due date', value: 'dueDate' },
  { title: 'Invoice Number', value: 'invoiceNumber' },
  { title: 'Actions', key: 'actions' },
];
const tableMobile = computed(() => width.value <= 643);

const fetch = async () => {
  loading.value = true;
  const createdBetween = month.value
    ? [
        Math.floor(subMonths(startOfYear(new Date(month.value.year, 0)), 1).getTime() / 1000).toString(),
        Math.floor(addMonths(endOfYear(new Date(month.value.year, 0)), 1).getTime() / 1000).toString(),
      ]
    : undefined;
  const invoiceNumberParam = !invoiceNumber.value ? undefined : invoiceNumber.value;
  if (invoiceNumberParam && !/[A-Z0-9]{8}-([0-9]{4}|DRAFT)/.exec(invoiceNumberParam)) {
    loading.value = false;
    invoices.value = [];
    return;
  }
  try {
    const result = await api
      .listInvoices(currentMerchantId, lastKeyPages.value[currentPage.value - 1], createdBetween?.join(','), invoiceNumberParam)
      .then((res) => res.data);
    invoices.value = result.items?.filter(
      (invoice) => !month.value || invoice.billingPeriod === `${month.value.year}-${(month.value.month + 1).toString().padStart(2, '0')}`,
    );
    if (lastKeyPages.value.length === currentPage.value) {
      lastKeyPages.value.push(result.lastKey);
    }
  } finally {
    loading.value = false;
  }
};

onMounted(fetch);
watch(() => ({ currentPage, month, invoiceNumber }), fetch, { deep: true });

const statusChipColor = (status: ListInvoices200ResponseItemsInnerStatusEnum) => {
  switch (status) {
    case 'paid':
      return 'success';
    case 'open':
      return 'warning';
    case 'overdue':
      return 'error';
    default:
      return 'grey';
  }
};
</script>
