<template>
  <div v-if="loading">
    <v-skeleton-loader height="50px" class="mx-4" />
    <v-skeleton-loader type="chip" />
    <v-skeleton-loader type="text" />
    <v-skeleton-loader type="chip" />
    <v-skeleton-loader type="button" />
  </div>

  <v-form v-else>
    <div class="d-flex">
      <v-btn v-if="updateFlow" :loading="submitLoading" :disabled="v$.$invalid || !changed" class="ms-auto" @click="updateUser">Save changes</v-btn>
      <v-btn v-else :loading="submitLoading" :disabled="v$.$invalid" class="ms-auto" @click="addUser">Create now</v-btn>
    </div>
    <p v-if="!updateFlow" class="mb-3">Click <span class="font-weight-bold">Create now</span> to create your new user and send them an invitation email.</p>
    <br /><br />
    <v-text-field
      v-model="userForm.email"
      type="email"
      label="Work email address"
      hint="Used as username during sign in"
      :disabled="!!currentUserId"
      :error-messages="validationErrors(v$.email.$errors)"
    />
    <v-text-field v-model="userForm.firstName" type="text" label="First name" clearable />
    <v-text-field v-model="userForm.lastName" type="text" label="Last name" clearable />
    <h3 class="text-primary text-uppercase mt-5">user permissions</h3>
    <v-checkbox
      v-model="userForm.admin"
      label="Admin user"
      persistent-hint
      hint="Check this box if this user will need access to admin functions like billing, user management and company management"
      inset
      color="primary"
    />
    <div class="d-flex my-5 flex-wrap">
      <v-checkbox
        v-model="userForm.allSites"
        label="Enable access to all sites"
        :disabled="userForm.admin"
        inset
        color="primary"
        class="me-5"
        min-width="200px"
      />
      <v-autocomplete
        v-if="!userForm.admin && !userForm.allSites"
        v-model="userForm.sites"
        label="Select sites"
        persistent-hint
        hint="Select which site(s) the user will be able to access"
        :error-messages="v$.sites.$invalid && v$.sites.$dirty ? 'At least one site must be selected' : []"
        multiple
        chips
        clearable
        closable-chips
        :items="sites"
        item-title="siteName"
        item-value="siteId"
        :loading="!sites.length"
      />
    </div>
    <div v-if="updateFlow">
      <v-row>
        <v-col v-if="authStore.hasCompanyScoped(Permission.User_ResetPassword)">
          <h3 class="text-primary text-uppercase">reset password</h3>
          <p class="my-4">The user will receive a temporary password valid for 7 days.</p>
          <v-btn-nd :loading="resetPasswordLoading" :disable="submitLoading" @click="resetUserPassword">Reset password</v-btn-nd>
        </v-col>
        <v-col>
          <h3 class="text-error text-uppercase">remove user</h3>
          <p class="my-4">
            When you remove a user, they will immediately lose access to basecamp.<br />
            If a user has been removed by mistake, you can always invite them back via the Users page.
          </p>
          <v-btn :loading="submitLoading" class="bg-error" @click="dissociateUser">Remove user</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script setup lang="ts">
import useVuelidate, { ErrorObject } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { AxiosError } from 'axios';
import * as _ from 'lodash-es';
import { onMounted, computed, onBeforeMount, inject, watch, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { SiteReduced } from '@/api/merchant-service/site';
import { User, UserInvite } from '@/api/merchant-service/users';
import { API } from '@/plugins/api';
import { useApplicationStore } from '@/store/application';
import { Permission, Role, useAuthorizationStore } from '@/store/authorization';

const router = useRouter();
const route = useRoute();
const appStore = useApplicationStore();
const authStore = useAuthorizationStore();
const api = inject<API>('api');

const sites = ref<SiteReduced[]>([]);
onMounted(async () => (sites.value = await api!.site.listAllSites()));

const currentUserId = ref<string | undefined>(undefined);
onBeforeMount(async () => {
  currentUserId.value = route.params.userId as string | undefined;
  if (!currentUserId.value) {
    loading.value = false;
  }
});
const updateFlow = computed(() => !!currentUserId.value);

interface UserUX {
  email: string;
  firstName?: string;
  lastName?: string;
  admin: boolean;
  allSites: boolean;
  sites: string[];
}
const fetchedUser = ref<UserUX | undefined>(undefined);
const userForm = ref<UserUX>({ email: '', firstName: undefined, lastName: undefined, admin: false, allSites: true, sites: [] });
const loading = ref(true);
watch(currentUserId, async () => {
  if (!currentUserId.value) {
    return;
  }
  const response = await api!.user.getUser(currentUserId.value);
  fetchedUser.value = {
    email: response.email,
    firstName: response.firstName,
    lastName: response.lastName,
    admin: response.companyRoles.includes(Role.CompanyOwner),
    allSites: response.siteRoles.some((r) => r.siteId === '*'),
    sites: response.siteRoles.filter((r) => r.siteId !== '*').map((r) => r.siteId),
  };
  userForm.value = _.cloneDeep(fetchedUser.value);
  loading.value = false;
});

const v$ = useVuelidate(
  {
    email: { required, email, $autoDirty: true },
    sites: { valid: () => userForm.value.admin || userForm.value.allSites || userForm.value.sites.length > 0, $autoDirty: true },
  },
  userForm,
);
const changed = computed(() => fetchedUser.value && !_.isEqual(userFormData(userForm.value), userFormData(fetchedUser.value)));
watch(
  () => userForm.value.admin,
  () => {
    if (userForm.value.admin) {
      userForm.value.allSites = true;
    }
  },
);

const submitLoading = ref(false);
const userFormData = (uxData: UserUX): UserInvite => ({
  email: uxData.email,
  firstName: _.isEmpty(uxData.firstName) ? undefined : uxData.firstName,
  lastName: _.isEmpty(uxData.lastName) ? undefined : uxData.lastName,
  companyRoles: uxData.admin ? [Role.CompanyOwner] : [],
  siteRoles: uxData.allSites ? [{ siteId: '*', role: Role.SiteManager }] : uxData.sites.map((siteId) => ({ siteId, role: Role.SiteManager })),
});
const addUser = async () => {
  try {
    submitLoading.value = true;
    await api!.user.inviteUser(userFormData(userForm.value));
    appStore.notifyUser({ message: 'User created successfully', type: 'success' });
    router.push('/users');
  } catch (e) {
    console.error(e);
    appStore.notifyUser({ message: 'Something went wrong. Please contact support.', type: 'error' });
  } finally {
    submitLoading.value = false;
  }
};
const updateUser = async () => {
  try {
    submitLoading.value = true;
    const userToSubmit: User = {
      id: currentUserId.value!,
      ...userFormData(userForm.value),
    };
    await api!.user.updateUser(userToSubmit);
    appStore.notifyUser({ message: 'User updated successfully', type: 'success' });
    router.push('/users');
  } catch (e) {
    console.error(e);
    appStore.notifyUser({ message: 'Something went wrong. Please contact support.', type: 'error' });
  } finally {
    submitLoading.value = false;
  }
};
const resetPasswordLoading = ref(false);
const resetUserPassword = async () => {
  try {
    resetPasswordLoading.value = true;
    await api!.user.resetUserPassword(currentUserId.value!);
    appStore.notifyUser({ message: 'Password reset email sent successfully', type: 'success' });
  } catch (e) {
    console.error(e);
    appStore.notifyUser({ message: 'Something went wrong. Please contact support.', type: 'error' });
  } finally {
    resetPasswordLoading.value = false;
  }
};
const dissociateUser = async () => {
  try {
    submitLoading.value = true;
    await api!.user.dissociateUser(currentUserId.value!);
    appStore.notifyUser({ message: 'User removed successfully', type: 'success' });
    router.push('/users');
  } catch (e) {
    console.error(e);
    let snackMessage = 'Something went wrong. Please contact support.';
    if (e instanceof AxiosError) {
      snackMessage = e.response?.data?.error || snackMessage;
    }
    appStore.notifyUser({ message: snackMessage, type: 'error' });
  } finally {
    submitLoading.value = false;
  }
};

const validationErrors = (errors: ErrorObject[]) => errors.map((e) => e.$message.toString());
</script>
