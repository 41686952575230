<template>
  <div v-if="authorizationStore.hasCompanyScoped(Permission.Site_CreateSite)" class="d-flex align-center">
    <v-btn-nd class="ms-auto" to="/new-site">New site</v-btn-nd>
  </div>

  <v-data-table :headers="headers" :items="items" :items-per-page="items.length" :loading="loading">
    <template #item.name="{ item }">
      <div class="d-flex align-center ms-2">
        <span class="text-sm font-weight-normal text-body">
          {{ item.siteName }}
        </span>
      </div>
    </template>

    <template #item.actions="{ item }">
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <router-link :to="`/sites/${item.siteId}`"><v-icon v-bind="props" size="14" color="secondary">mdi-pencil</v-icon></router-link>
        </template>
        Edit site {{ item.siteName }}
      </v-tooltip>
      &nbsp;
      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <router-link :to="`/devices?selectedSite=${item.siteId}`"><v-icon v-bind="props" size="14" color="secondary">mdi-cellphone</v-icon></router-link>
        </template>
        See devices for {{ item.siteName }}
      </v-tooltip>
      &nbsp;
      <v-tooltip v-if="authorizationStore.isStaff" location="bottom">
        <template #activator="{ props }">
          <router-link :to="`/sites/${item.siteId}/internal`"><v-icon v-bind="props" size="14" color="secondary">mdi-cog</v-icon></router-link>
        </template>
        Internal settings for {{ item.siteName }}
      </v-tooltip>
    </template>

    <template #bottom></template>
  </v-data-table>
</template>

<script setup lang="ts">
import { ref, onMounted, inject } from 'vue';
import { SiteReduced } from '@/api/merchant-service/site';
import { API } from '@/plugins/api';
import { Permission, useAuthorizationStore } from '@/store/authorization';

const api = inject<API>('api');

const authorizationStore = useAuthorizationStore();

const loading = ref(false);
const items = ref<SiteReduced[]>([]);

onMounted(async () => {
  loading.value = true;
  try {
    items.value = await api!.site.listAllSites();
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
});

const headers = [
  { title: 'Name', value: 'name' },
  { title: 'Actions', value: 'actions' },
];
</script>
