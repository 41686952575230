<template>
  <div class="d-flex flex-wrap justify-center">
    <SiteSelector v-model="selectedSite" />
    <v-btn-nd
      v-if="authorizationStore.hasCompanyScoped(Permission.Device_OrderDevice)"
      :disabled="!selectedSite"
      :to="`/request-new-device?siteId=${selectedSite}`"
      class="ma-3"
    >
      Order devices
    </v-btn-nd>
    <v-btn-nd
      v-if="posthog?.isFeatureEnabled('assign-new-device') && authorizationStore.hasAnySiteScoped(Permission.Device_AssignDevice)"
      :disabled="!selectedSite"
      :to="`/assign-new-device?selectedSite=${selectedSite}`"
      class="ma-3"
    >
      Assign device
    </v-btn-nd>
  </div>

  <h2 v-if="!selectedSite" class="text-center text-gray">Please select a site to view devices</h2>

  <div v-if="selectedSite">
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="devicesLoading"
      :hide-default-footer="true"
      :items-per-page="itemsPerPage"
      :mobile="null"
      :mobile-breakpoint="800"
      :no-data-text="hasItemsWithoutSearch ? 'No devices match this search' : 'There are no devices allocated to this site'"
    >
      <template #top>
        <v-text-field v-model="search" label="Search for a device"></v-text-field>
      </template>

      <template #item.deviceSlogan="{ item }">
        <EditDeviceSlogan :site-id="selectedSite" :device-id="item.deviceId!" :slogan="item.deviceSlogan" />
      </template>

      <template #item.lastTransactionAt="{ value }">
        <v-tooltip v-if="value" activator="parent" location="top">{{ timeSinceDate(value) }}</v-tooltip>
        {{ formatDate(value) }}
      </template>

      <template #item.lastActivityAt="{ value }">
        <v-tooltip v-if="value" activator="parent" location="top">{{ timeSinceDate(value) }}</v-tooltip>
        {{ formatDate(value) }}
      </template>

      <template #item.firmwareVersion="{ value }">
        {{ value.replace('Castles_Android', '') }}
      </template>

      <template #item.statusColor="{ value }">
        <v-tooltip activator="parent" location="top">{{ getStatusTooltipText(value) }}</v-tooltip>
        <v-badge dot inline :color="value || 'white'"></v-badge>
      </template>

      <template #bottom></template>
    </v-data-table>
    <v-pagination v-model="page" color="secondary" :length="pageCount" />
  </div>

  <v-alert v-if="thereWasAProblem" color="red lighten-2" dark>Issue occurred when gathering your devices. Please contact TableYeti support</v-alert>
</template>

<script setup lang="ts">
import { formatRelative, intervalToDuration } from 'date-fns';
import { PostHog } from 'posthog-js';
import { inject, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Device } from '@/api/merchant-service/device';
import { API } from '@/plugins/api';
import { Permission, useAuthorizationStore } from '@/store/authorization';

const api = inject<API>('api');
const posthog = inject<PostHog>('posthog');
const route = useRoute();
const authorizationStore = useAuthorizationStore();

const thereWasAProblem = ref(false);
const selectedSite = ref<string | undefined>(undefined);
const page = ref(-1);
const pageCount = ref(0);
const itemsPerPage = ref(10);
const items = ref<EnrichedDevice[]>([]);
const hasItemsWithoutSearch = ref(false);
const devicesLoading = ref(false);
const search = ref('');
const headers = ref([
  {
    title: '',
    value: 'statusColor',
  },
  {
    title: 'Serial',
    value: 'deviceId',
  },
  {
    title: 'Label',
    value: 'deviceSlogan',
    width: '200px',
  },
  {
    title: 'Model',
    value: 'model',
  },
  {
    title: 'Last Transaction',
    value: 'lastTransactionAt',
  },
  {
    title: 'Last Activity',
    value: 'lastActivityAt',
  },
  {
    title: 'Firmware Version',
    value: 'firmwareVersion',
  },
]);

function getStatusTooltipText(color: string) {
  switch (color) {
    case 'green':
      return 'Last active in last 24h';
    case 'orange':
      return 'DLast active between 24h and 1 week ago';
    case 'red':
      return 'Last active more than 1 week ago';
    default:
      return 'Unknown status';
  }
}
function getColor(dateTime: string) {
  if (!dateTime) return 'red';

  const dateSince = intervalToDuration({ start: new Date(dateTime), end: new Date() });
  if (!dateSince.days) return 'green';
  if (dateSince.days! > 7) return 'orange';
  return 'red';
}

function formatDate(date: string) {
  if (!date) return 'Never';
  return new Date(date).toLocaleString();
}

type EnrichedDevice = Device & {
  deviceSlogan?: string;
  statusColor?: string;
};

onMounted(() => {
  if (route.query.selectedSite) {
    selectedSite.value = route.query.selectedSite as string;
  }
});

function timeSinceDate(dateTime: string) {
  if (!dateTime) return 'Never';
  const dateSince = intervalToDuration({ start: new Date(dateTime), end: new Date() });
  if (dateSince.days! > 7) {
    return `${dateSince.days!} day${dateSince.days! > 1 ? 's' : ''} ago`;
  }
  return formatRelative(new Date(dateTime), new Date());
}

const loadDevices = async (newPage: number) => {
  devicesLoading.value = true;
  try {
    const result = await api!.device.listDevices(selectedSite.value!, newPage);
    items.value = result.items ?? [];
    pageCount.value = result.totalPages;
    await fetchLoadedDeviceDetails();
  } catch (error) {
    console.error(error);
    thereWasAProblem.value = true;
  } finally {
    devicesLoading.value = false;
  }
};

const fetchLoadedDeviceDetails = async () => {
  items.value.forEach(async (item) => {
    const deviceDetails = await api!.device.fetchDeviceConfig(selectedSite.value!, item.deviceId!);
    const index = items.value.findIndex((i) => i.deviceId === deviceDetails.id);
    items.value[index].deviceSlogan = deviceDetails.signature?.deviceSlogan || deviceDetails.id!.slice(-4);
    items.value[index].statusColor = getColor(item.lastActivityAt!);
  });
};

watch(selectedSite, async (newSelectedSite) => {
  let queryParams = '';
  if (newSelectedSite && newSelectedSite !== 'null') {
    queryParams = '?selectedSite=' + newSelectedSite;
    page.value = 1;
    search.value = '';
    await loadDevices(1);
    hasItemsWithoutSearch.value = items.value.length > 0;
  }
  history.pushState({}, '', route.path + queryParams);
});
watch(page, async (newPage) => {
  await loadDevices(newPage);
});
watch(search, async (newSearch: string) => {
  devicesLoading.value = true;
  try {
    const result = await api!.device.listDevices(selectedSite.value!, 1, newSearch);
    items.value = result.items ?? [];
    pageCount.value = result.totalPages;
    await fetchLoadedDeviceDetails();
  } catch (error) {
    console.error(error);
    thereWasAProblem.value = true;
  } finally {
    devicesLoading.value = false;
  }
});
</script>

<style>
div.v-data-table__td-sort-select {
  display: none;
}
</style>
